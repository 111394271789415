import type { ElementRef, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, input, signal, viewChild } from '@angular/core';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TuiButton } from '@taiga-ui/core';
import { AudioPlaybackService } from './audio-playback.service';
import { delay } from 'rxjs';

@Component({
	selector: 'app-playback-visualizer',
	templateUrl: './audio-playback-visualizer.component.html',
	styleUrls: ['./audio-playback-visualizer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [AudioPlaybackService],
	standalone: true,
	host: {
		'[style.position]': '"fixed"',
	},
	imports: [TuiButton],
	animations: [
		trigger('showHide', [
			state('hide', style({ width: 0, opacity: 0 })),
			state('show', style({ width: '*', opacity: 1 })),
			transition('hide <=> show', animate('300ms ease-in-out')),
		]),
		trigger('showHideSecond', [
			state('hide', style({ transform: 'translateY(-20px)', opacity: 0, height: 0 })),
			state('show', style({ transform: 'translateY(0)', opacity: 1, height: '*' })),
			transition('hide <=> show', animate('500ms ease-in-out')),
		]),
	],
})
export class AudioPlaybackVisualizerComponent implements OnInit {
	private readonly containerRef = viewChild.required<ElementRef<HTMLCanvasElement>>('container');
	private readonly audioPlaybackService = inject(AudioPlaybackService);

	private readonly destroyRef = inject(DestroyRef);

	readonly url = input.required<string>();
	readonly volume = input(0.5);
	readonly looped = input(false);
	readonly radius = input(4.8);
	readonly isVisible = signal(false);
	readonly playing = signal(false);

	ngOnInit(): void {
		this.audioPlaybackService.loadScene(this.containerRef().nativeElement, this.radius());
		this.audioPlaybackService.initAudioProcessing(this.volume(), this.url(), this.looped());
		this.audioPlaybackService.addTrackInfo(this.url()).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
	}

	onClick(): void {
		this.playing.set(true);
		this.audioPlaybackService.audioElement.autoplay = true;
		this.audioPlaybackService.play();
		this.audioPlaybackService.initAudioContextAfterUserGesture();
	}

	stop(): void {
		this.audioPlaybackService.stop();
		this.playing.set(false);
	}

	playPause(): void {
		if (this.playing()) {
			this.playing.set(false);
			this.audioPlaybackService.pause();
		} else {
			this.playing.set(true);
			this.audioPlaybackService.play();
		}
	}

	setIsVisible(): void {
		this.isVisible.update((v) => !v);
		this.audioPlaybackService.initAudioContextAfterUserGesture();
	}
}
