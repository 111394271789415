<div class="step-container">
	<div class="line"></div>
	<div class="step">
		<div class="indicator" [class.active]="isActive()"></div>

		<div class="label">
			<ng-content></ng-content>
		</div>
	</div>
</div>
