<div class="header-control">
 	<h3 class="heading">
		Frameworks
	</h3>
	<input type="checkbox" tuiCheckbox [(ngModel)]="isDeprecated">
</div>

	<tui-scrollbar>
		<div class="container">
			@if (computedData().length >= 1) 	{
				@for(item of computedData(); track item.id) {
					<app-deprecation-clock [frameworkInfo]="item"></app-deprecation-clock>
				}
			} @else {
				<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 200 150"
			width="200"
			height="150"
			style="display: block; margin: auto; fill: #ccc;"
		>
			<!-- Circle for empty state -->
			<circle cx="100" cy="50" r="40" stroke="#ddd" stroke-width="5" fill="none" />

			<!-- Line crossing the circle -->
			<line x1="65" y1="20" x2="135" y2="80" stroke="#ddd" stroke-width="5" />

			<!-- Text below the circle -->
			<text
				x="50%"
				y="130"
				text-anchor="middle"
				font-size="16"
				fill="#888"
				font-family="Arial, sans-serif"
			>
				Temporary Empty
			</text>
		</svg>

			}
		</div>

	</tui-scrollbar>
