import { ThinDepthOfFieldMergePostProcess } from "../../../PostProcesses/thinDepthOfFieldMergePostProcess.js";
import { FrameGraphPostProcessTask } from "./postProcessTask.js";

/**
 * @internal
 */
export class FrameGraphDepthOfFieldMergeTask extends FrameGraphPostProcessTask {
  constructor(name, frameGraph, thinPostProcess) {
    super(name, frameGraph, thinPostProcess || new ThinDepthOfFieldMergePostProcess(name, frameGraph.engine));
    this.blurSteps = [];
  }
  record(skipCreationOfDisabledPasses = false) {
    if (this.sourceTexture === undefined || this.circleOfConfusionTexture === undefined || this.blurSteps.length === 0) {
      throw new Error(`FrameGraphBloomMergeTask "${this.name}": sourceTexture, circleOfConfusionTexture and blurSteps are required`);
    }
    this.postProcess.updateEffect("#define BLUR_LEVEL " + (this.blurSteps.length - 1) + "\n");
    const pass = super.record(skipCreationOfDisabledPasses, undefined, context => {
      context.bindTextureHandle(this._postProcessDrawWrapper.effect, "circleOfConfusionSampler", this.circleOfConfusionTexture);
      this.blurSteps.forEach((handle, index) => {
        if (index === this.blurSteps.length - 1) {
          context.setTextureSamplingMode(handle, 2);
        }
        context.bindTextureHandle(this._postProcessDrawWrapper.effect, "blurStep" + (this.blurSteps.length - index - 1), handle);
      });
    });
    pass.useTexture(this.circleOfConfusionTexture);
    for (const handle of this.blurSteps) {
      pass.useTexture(handle);
    }
    return pass;
  }
}
