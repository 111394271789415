import { backbufferColorTextureHandle, backbufferDepthStencilTextureHandle } from "../../frameGraphTypes.js";
import { FrameGraphTask } from "../../frameGraphTask.js";
import { ObjectRenderer } from "../../../Rendering/objectRenderer.js";
/**
 * Task used to render objects to a texture.
 */
export class FrameGraphObjectRendererTask extends FrameGraphTask {
  /**
   * Gets or sets the camera used to render the objects.
   */
  get camera() {
    return this._camera;
  }
  set camera(camera) {
    this._camera = camera;
    this._renderer.activeCamera = this.camera;
  }
  /**
   * The object renderer used to render the objects.
   */
  get objectRenderer() {
    return this._renderer;
  }
  get name() {
    return this._name;
  }
  set name(value) {
    this._name = value;
    if (this._renderer) {
      this._renderer.name = value;
    }
  }
  /**
   * Constructs a new object renderer task.
   * @param name The name of the task.
   * @param frameGraph The frame graph the task belongs to.
   * @param scene The scene the frame graph is associated with.
   * @param options The options of the object renderer.
   */
  constructor(name, frameGraph, scene, options) {
    super(name, frameGraph);
    /**
     * The dependencies of the task (optional).
     */
    this.dependencies = [];
    /**
     * If depth testing should be enabled (default is true).
     */
    this.depthTest = true;
    /**
     * If depth writing should be enabled (default is true).
     */
    this.depthWrite = true;
    this._scene = scene;
    this._renderer = new ObjectRenderer(name, scene, options);
    this.name = name;
    this._renderer.onBeforeRenderingManagerRenderObservable.add(() => {
      if (!this._renderer.options.doNotChangeAspectRatio) {
        scene.updateTransformMatrix(true);
      }
    });
    this.outputTexture = this._frameGraph.textureManager.createDanglingHandle();
    this.outputDepthTexture = this._frameGraph.textureManager.createDanglingHandle();
  }
  isReady() {
    return this._renderer.isReadyForRendering(this._textureWidth, this._textureHeight);
  }
  record(skipCreationOfDisabledPasses = false, additionalExecute) {
    if (this.destinationTexture === undefined || this.objectList === undefined) {
      throw new Error(`FrameGraphObjectRendererTask ${this.name}: destinationTexture and objectList are required`);
    }
    const outputTextureDescription = this._frameGraph.textureManager.getTextureDescription(this.destinationTexture);
    let depthEnabled = false;
    if (this.depthTexture !== undefined) {
      if (this.depthTexture === backbufferDepthStencilTextureHandle && this.destinationTexture !== backbufferColorTextureHandle) {
        throw new Error(`FrameGraphObjectRendererTask ${this.name}: the back buffer color texture is the only color texture allowed when the depth is the back buffer depth/stencil`);
      }
      if (this.depthTexture !== backbufferDepthStencilTextureHandle && this.destinationTexture === backbufferColorTextureHandle) {
        throw new Error(`FrameGraphObjectRendererTask ${this.name}: the back buffer depth/stencil texture is the only depth texture allowed when the destination is the back buffer color`);
      }
      const depthTextureDescription = this._frameGraph.textureManager.getTextureDescription(this.depthTexture);
      if (depthTextureDescription.options.samples !== outputTextureDescription.options.samples) {
        throw new Error(`FrameGraphObjectRendererTask ${this.name}: the depth texture and the output texture must have the same number of samples`);
      }
      depthEnabled = true;
    }
    this._frameGraph.textureManager.resolveDanglingHandle(this.outputTexture, this.destinationTexture);
    if (this.depthTexture !== undefined) {
      this._frameGraph.textureManager.resolveDanglingHandle(this.outputDepthTexture, this.depthTexture);
    }
    this._textureWidth = outputTextureDescription.size.width;
    this._textureHeight = outputTextureDescription.size.height;
    const pass = this._frameGraph.addRenderPass(this.name);
    pass.setRenderTarget(this.destinationTexture);
    pass.setRenderTargetDepth(this.depthTexture);
    pass.setExecuteFunc(context => {
      this._renderer.renderList = this.objectList.meshes;
      this._renderer.particleSystemList = this.objectList.particleSystems;
      context.setDepthStates(this.depthTest && depthEnabled, this.depthWrite && depthEnabled);
      context.render(this._renderer, this._textureWidth, this._textureHeight);
      additionalExecute?.(context);
    });
    if (this.dependencies !== undefined) {
      for (const handle of this.dependencies) {
        pass.useTexture(handle);
      }
    }
    if (!skipCreationOfDisabledPasses) {
      const passDisabled = this._frameGraph.addRenderPass(this.name + "_disabled", true);
      passDisabled.setRenderTarget(this.destinationTexture);
      passDisabled.setRenderTargetDepth(this.depthTexture);
      passDisabled.setExecuteFunc(_context => {});
      if (this.dependencies !== undefined) {
        for (const handle of this.dependencies) {
          passDisabled.useTexture(handle);
        }
      }
    }
  }
  dispose() {
    this._renderer.dispose();
    super.dispose();
  }
}
