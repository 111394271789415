import { NodeRenderGraphBlock } from "../../nodeRenderGraphBlock.js";
import { RegisterClass } from "../../../../Misc/typeStore.js";
import { NodeRenderGraphBlockConnectionPointTypes } from "../../Types/nodeRenderGraphTypes.js";
import { FrameGraphCopyToTextureTask } from "../../../Tasks/Texture/copyToTextureTask.js";
/**
 * Block used to copy a texture
 */
export class NodeRenderGraphCopyTextureBlock extends NodeRenderGraphBlock {
  /**
   * Gets the frame graph task associated with this block
   */
  get task() {
    return this._frameGraphTask;
  }
  /**
   * Create a new NodeRenderGraphCopyTextureBlock
   * @param name defines the block name
   * @param frameGraph defines the hosting frame graph
   * @param scene defines the hosting scene
   */
  constructor(name, frameGraph, scene) {
    super(name, frameGraph, scene);
    this.registerInput("source", NodeRenderGraphBlockConnectionPointTypes.Texture);
    this.registerInput("destination", NodeRenderGraphBlockConnectionPointTypes.Texture);
    this.registerOutput("output", NodeRenderGraphBlockConnectionPointTypes.BasedOnInput);
    this.source.addAcceptedConnectionPointTypes(NodeRenderGraphBlockConnectionPointTypes.TextureAllButBackBuffer);
    this.destination.addAcceptedConnectionPointTypes(NodeRenderGraphBlockConnectionPointTypes.TextureAll);
    this.output._typeConnectionSource = this.destination;
    this._frameGraphTask = new FrameGraphCopyToTextureTask(name, frameGraph);
  }
  /**
   * Gets the current class name
   * @returns the class name
   */
  getClassName() {
    return "NodeRenderGraphCopyTextureBlock";
  }
  /**
   * Gets the source input component
   */
  get source() {
    return this._inputs[0];
  }
  /**
   * Gets the destination input component
   */
  get destination() {
    return this._inputs[1];
  }
  /**
   * Gets the output component
   */
  get output() {
    return this._outputs[0];
  }
  _buildBlock(state) {
    super._buildBlock(state);
    this._frameGraphTask.name = this.name;
    this.output.value = this._frameGraphTask.outputTexture; // the value of the output connection point is the "output" texture of the task
    const sourceConnectedPoint = this.source.connectedPoint;
    if (sourceConnectedPoint) {
      this._frameGraphTask.sourceTexture = sourceConnectedPoint.value;
    }
    const destinationConnectedPoint = this.destination.connectedPoint;
    if (destinationConnectedPoint) {
      this._frameGraphTask.destinationTexture = destinationConnectedPoint.value;
    }
  }
}
RegisterClass("BABYLON.NodeRenderGraphCopyTextureBlock", NodeRenderGraphCopyTextureBlock);
