import type { WritableSignal } from '@angular/core';
import type { FrameworkInfoOutput } from '@graphql/types';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, signal, ViewEncapsulation } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { DeprecationClockComponent } from '@core/components/deprecation-clock/deprecation-clock.component';

import { TuiScrollbar } from '@taiga-ui/core';
import { TuiCheckbox } from '@taiga-ui/kit';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs';

interface FrameworkInfoData {
	data: FrameworkInfoOutput[];
}

@Component({
	selector: 'app-framework-slider',
	standalone: true,
	imports: [CommonModule, TuiScrollbar, TuiCheckbox, DeprecationClockComponent, FormsModule],
	templateUrl: './framework-slider.component.html',
	styleUrl: './framework-slider.component.scss',
	encapsulation: ViewEncapsulation.Emulated,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FrameworkSliderComponent {
	private readonly apollo = inject(Apollo);
	private readonly data = toSignal(
		this.apollo
			.query<FrameworkInfoData>({
				query: gql`
					query Data {
						data: getFrameworks {
							id
							name
							developer
							dateCreated
							developer
							description
							authorId
							isDeprecated
						}
					}
				`,
			})
			.pipe(map(({ data }) => data.data)),
		{ initialValue: [] },
	);

	readonly computedData = computed(() => {
		return this.data().filter((value) => {
			if (this.isDeprecated()) {
				return value.isDeprecated;
			} else if (this.isDeprecated() === null) {
				return value;
			} else {
				return !value.isDeprecated;
			}
		});
	});

	readonly isDeprecated: WritableSignal<boolean | null> = signal(null);
}
