<div class="wrapper">
	<div class="header">
		<button
			tuiButton
			[appearance]="authType() === 'register' ? 'active' : ''"
			(click)="authType.set('register')"
		>Register</button>

		<div class="hr"></div>

		<button
			tuiButton
			[appearance]="authType() === 'login' ? 'active' : ''"
			(click)="authType.set('login')"
		>Login</button>
	</div>

	<div class="content">
		@if(authType() === 'register') {
				<app-register-form></app-register-form>
		} @else {
				<app-login-form></app-login-form>
		}
	</div>

</div>
