import type { ElementRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, viewChildren, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@core/services';
import { TuiButton, TuiError, TuiTextfieldOptionsDirective } from '@taiga-ui/core';
import { TuiButtonLoading, TuiFieldErrorPipe } from '@taiga-ui/kit';
import { TuiInputModule } from '@taiga-ui/legacy';
import { FormGroup } from '@ui/additional';
import gsap from 'gsap';

@Component({
	selector: 'app-login-form',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TuiButton,
		TuiButtonLoading,
		TuiError,
		TuiFieldErrorPipe,
		TuiInputModule,
		TuiTextfieldOptionsDirective,
	],
	templateUrl: './login-form.component.html',
	styleUrl: './login-form.component.scss',
	encapsulation: ViewEncapsulation.Emulated,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent implements OnInit {
	private readonly router = inject(Router);
	private readonly authService = inject(AuthService);
	private readonly destroyRef = inject(DestroyRef);
	private readonly fieldSets = viewChildren<ElementRef<HTMLElement>>('field');

	readonly form = new FormGroup({
		username: new FormControl('', {
			nonNullable: true,
			validators: [Validators.required, Validators.email],
		}),
		password: new FormControl('', {
			nonNullable: true,
			validators: [Validators.required, Validators.minLength(3)],
		}),
	});

	ngOnInit(): void {
		gsap
			.timeline()
			.from(this.fieldSets()[0].nativeElement, { x: -100, opacity: 0, duration: 0.5 }, '-=0.5') // Первое поле
			.from(this.fieldSets()[1].nativeElement, { x: '100%', opacity: 0, duration: 0.5 }, '-=0.3') // Второе поле
			.from(this.fieldSets()[2].nativeElement, { y: 100, opacity: 0, duration: 0.5 }, '-=0.3'); // Третье поле
	}

	submit(): void {
		const data = this.form.getRawValue();

		if (this.form.invalid) {
			this.form.endProcessing();
		}
		this.form.startProcessing(true);
		this.authService
			.login(data)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: () => {
					this.router.navigate(['/account']);
				},
				error: (err) => {
					this.form.showServerError(err);
					this.form.endProcessing();
				},
			});
	}
}
