import { __decorate } from "@babylonjs/core/tslib.es6.js";
import { Color4 } from "@babylonjs/core/Maths/math.color.js";
import { Constants } from "@babylonjs/core/Engines/constants.js";
import { EffectFallbacks } from "@babylonjs/core/Materials/effectFallbacks.js";
import { MaterialDefines } from "@babylonjs/core/Materials/materialDefines.js";
import { PushMaterial } from "@babylonjs/core/Materials/pushMaterial.js";
import { RegisterClass } from "@babylonjs/core/Misc/typeStore.js";
import { serialize } from "@babylonjs/core/Misc/decorators.js";
import { SerializationHelper } from "@babylonjs/core/Misc/decorators.serialization.js";
import { VertexBuffer } from "@babylonjs/core/Buffers/buffer.js";
import "./shaders/mrdlBackglow.fragment.js";
import "./shaders/mrdlBackglow.vertex.js";
import { HandleFallbacksForShadows, PrepareAttributesForInstances, PrepareDefinesForAttributes, PrepareUniformsAndSamplersList } from "@babylonjs/core/Materials/materialHelper.functions.js";
/** @hidden */
class MRDLBackglowMaterialDefines extends MaterialDefines {
  constructor() {
    super();
    this._needNormals = true;
    this._needUVs = true;
    this.rebuild();
  }
}
export class MRDLBackglowMaterial extends PushMaterial {
  constructor(name, scene) {
    super(name, scene);
    /**
     * Gets or sets the bevel radius on the backglow. If this value is changed, update the lineWidth to match.
     */
    this.bevelRadius = 0.16;
    /**
     * Gets or sets the line width of the backglow.
     */
    this.lineWidth = 0.16;
    /**
     * Gets or sets whether to use absolute sizes when calculating effects on the backglow.
     * Since desktop and VR/AR have different relative sizes, it's usually best to keep this false.
     */
    this.absoluteSizes = false;
    /**
     * Gets or sets the tuning motion of the backglow.
     */
    this.tuningMotion = 0.0;
    /**
     * Gets or sets the motion of the backglow.
     */
    this.motion = 1.0;
    /**
     * Gets or sets the maximum intensity of the backglow.
     */
    this.maxIntensity = 0.7;
    /**
     * Gets or sets the fade-in exponent of the intensity of the backglow.
     */
    this.intensityFadeInExponent = 2.0;
    /**
     * Gets or sets the start of the outer fuzz effect on the backglow.
     */
    this.outerFuzzStart = 0.04;
    /**
     * Gets or sets the end of the outer fuzz effect on the backglow.
     */
    this.outerFuzzEnd = 0.04;
    /**
     * Gets or sets the color of the backglow.
     */
    this.color = new Color4(0.682353, 0.698039, 1, 1);
    /**
     * Gets or sets the inner color of the backglow.
     */
    this.innerColor = new Color4(0.356863, 0.392157, 0.796078, 1);
    /**
     * Gets or sets the blend exponent of the backglow.
     */
    this.blendExponent = 1.5;
    /**
     * Gets or sets the falloff of the backglow.
     */
    this.falloff = 2.0;
    /**
     * Gets or sets the bias of the backglow.
     */
    this.bias = 0.5;
    this.alphaMode = Constants.ALPHA_ADD;
    this.disableDepthWrite = true;
    this.backFaceCulling = false;
  }
  needAlphaBlending() {
    return true;
  }
  needAlphaTesting() {
    return false;
  }
  getAlphaTestTexture() {
    return null;
  }
  // Methods
  isReadyForSubMesh(mesh, subMesh) {
    const drawWrapper = subMesh._drawWrapper;
    if (this.isFrozen) {
      if (drawWrapper.effect && drawWrapper._wasPreviouslyReady) {
        return true;
      }
    }
    if (!subMesh.materialDefines) {
      subMesh.materialDefines = new MRDLBackglowMaterialDefines();
    }
    const defines = subMesh.materialDefines;
    const scene = this.getScene();
    if (this._isReadyForSubMesh(subMesh)) {
      return true;
    }
    const engine = scene.getEngine();
    // Attribs
    PrepareDefinesForAttributes(mesh, defines, false, false);
    // Get correct effect
    if (defines.isDirty) {
      defines.markAsProcessed();
      scene.resetCachedMaterial();
      // Fallbacks
      const fallbacks = new EffectFallbacks();
      if (defines.FOG) {
        fallbacks.addFallback(1, "FOG");
      }
      HandleFallbacksForShadows(defines, fallbacks);
      defines.IMAGEPROCESSINGPOSTPROCESS = scene.imageProcessingConfiguration.applyByPostProcess;
      //Attributes
      const attribs = [VertexBuffer.PositionKind];
      if (defines.NORMAL) {
        attribs.push(VertexBuffer.NormalKind);
      }
      if (defines.UV1) {
        attribs.push(VertexBuffer.UVKind);
      }
      if (defines.UV2) {
        attribs.push(VertexBuffer.UV2Kind);
      }
      if (defines.VERTEXCOLOR) {
        attribs.push(VertexBuffer.ColorKind);
      }
      if (defines.TANGENT) {
        attribs.push(VertexBuffer.TangentKind);
      }
      PrepareAttributesForInstances(attribs, defines);
      // Legacy browser patch
      const shaderName = "mrdlBackglow";
      const join = defines.toString();
      const uniforms = ["world", "worldView", "worldViewProjection", "view", "projection", "viewProjection", "cameraPosition", "_Bevel_Radius_", "_Line_Width_", "_Absolute_Sizes_", "_Tuning_Motion_", "_Motion_", "_Max_Intensity_", "_Intensity_Fade_In_Exponent_", "_Outer_Fuzz_Start_", "_Outer_Fuzz_End_", "_Color_", "_Inner_Color_", "_Blend_Exponent_", "_Falloff_", "_Bias_"];
      const samplers = [];
      const uniformBuffers = [];
      PrepareUniformsAndSamplersList({
        uniformsNames: uniforms,
        uniformBuffersNames: uniformBuffers,
        samplers: samplers,
        defines: defines,
        maxSimultaneousLights: 4
      });
      subMesh.setEffect(scene.getEngine().createEffect(shaderName, {
        attributes: attribs,
        uniformsNames: uniforms,
        uniformBuffersNames: uniformBuffers,
        samplers: samplers,
        defines: join,
        fallbacks: fallbacks,
        onCompiled: this.onCompiled,
        onError: this.onError,
        indexParameters: {
          maxSimultaneousLights: 4
        }
      }, engine), defines);
    }
    if (!subMesh.effect || !subMesh.effect.isReady()) {
      return false;
    }
    defines._renderId = scene.getRenderId();
    drawWrapper._wasPreviouslyReady = true;
    return true;
  }
  bindForSubMesh(world, mesh, subMesh) {
    const scene = this.getScene();
    const defines = subMesh.materialDefines;
    if (!defines) {
      return;
    }
    const effect = subMesh.effect;
    if (!effect) {
      return;
    }
    this._activeEffect = effect;
    // Matrices
    this.bindOnlyWorldMatrix(world);
    this._activeEffect.setMatrix("viewProjection", scene.getTransformMatrix());
    this._activeEffect.setVector3("cameraPosition", scene.activeCamera.position);
    // "Rounded Rectangle"
    this._activeEffect.setFloat("_Bevel_Radius_", this.bevelRadius);
    this._activeEffect.setFloat("_Line_Width_", this.lineWidth);
    this._activeEffect.setFloat("_Absolute_Sizes_", this.absoluteSizes ? 1.0 : 0.0);
    // "Animation"
    this._activeEffect.setFloat("_Tuning_Motion_", this.tuningMotion);
    this._activeEffect.setFloat("_Motion_", this.motion);
    this._activeEffect.setFloat("_Max_Intensity_", this.maxIntensity);
    this._activeEffect.setFloat("_Intensity_Fade_In_Exponent_", this.intensityFadeInExponent);
    this._activeEffect.setFloat("_Outer_Fuzz_Start_", this.outerFuzzStart);
    this._activeEffect.setFloat("_Outer_Fuzz_End_", this.outerFuzzEnd);
    // "Color"
    this._activeEffect.setDirectColor4("_Color_", this.color);
    this._activeEffect.setDirectColor4("_Inner_Color_", this.innerColor);
    this._activeEffect.setFloat("_Blend_Exponent_", this.blendExponent);
    // "Inner Transition"
    this._activeEffect.setFloat("_Falloff_", this.falloff);
    this._activeEffect.setFloat("_Bias_", this.bias);
    this._afterBind(mesh, this._activeEffect, subMesh);
  }
  /**
   * Get the list of animatables in the material.
   * @returns the list of animatables object used in the material
   */
  getAnimatables() {
    return [];
  }
  dispose(forceDisposeEffect) {
    super.dispose(forceDisposeEffect);
  }
  clone(name) {
    return SerializationHelper.Clone(() => new MRDLBackglowMaterial(name, this.getScene()), this);
  }
  serialize() {
    const serializationObject = SerializationHelper.Serialize(this);
    serializationObject.customType = "BABYLON.MRDLBackglowMaterial";
    return serializationObject;
  }
  getClassName() {
    return "MRDLBackglowMaterial";
  }
  // Statics
  static Parse(source, scene, rootUrl) {
    return SerializationHelper.Parse(() => new MRDLBackglowMaterial(source.name, scene), source, scene, rootUrl);
  }
}
__decorate([serialize()], MRDLBackglowMaterial.prototype, "bevelRadius", void 0);
__decorate([serialize()], MRDLBackglowMaterial.prototype, "lineWidth", void 0);
__decorate([serialize()], MRDLBackglowMaterial.prototype, "absoluteSizes", void 0);
__decorate([serialize()], MRDLBackglowMaterial.prototype, "tuningMotion", void 0);
__decorate([serialize()], MRDLBackglowMaterial.prototype, "motion", void 0);
__decorate([serialize()], MRDLBackglowMaterial.prototype, "maxIntensity", void 0);
__decorate([serialize()], MRDLBackglowMaterial.prototype, "intensityFadeInExponent", void 0);
__decorate([serialize()], MRDLBackglowMaterial.prototype, "outerFuzzStart", void 0);
__decorate([serialize()], MRDLBackglowMaterial.prototype, "outerFuzzEnd", void 0);
__decorate([serialize()], MRDLBackglowMaterial.prototype, "color", void 0);
__decorate([serialize()], MRDLBackglowMaterial.prototype, "innerColor", void 0);
__decorate([serialize()], MRDLBackglowMaterial.prototype, "blendExponent", void 0);
__decorate([serialize()], MRDLBackglowMaterial.prototype, "falloff", void 0);
__decorate([serialize()], MRDLBackglowMaterial.prototype, "bias", void 0);
RegisterClass("BABYLON.GUI.MRDLBackglowMaterial", MRDLBackglowMaterial);
