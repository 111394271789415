import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ScrollService } from '@core/components/scroll-to-top/scroll.service';
import { TuiButton } from '@taiga-ui/core';
import { toObservable } from '@ui/additional';
import { DeviceService } from '@ui/services';
import { combineLatest, distinctUntilChanged, map } from 'rxjs';

@Component({
	selector: 'app-scroll-to-top',
	standalone: true,
	imports: [TuiButton],
	template: ` <button
		[style.visibility]="visibility() ? 'visible' : 'hidden'"
		tuiButton
		iconEnd="assets/images/svg/up.svg"
		appearance="flat"
		(click)="scrollToTop()"
	></button>`,
	styles: `
		button {
			position: fixed;
			right: 2rem;
			bottom: 2rem;

			@media print {
				display: none;
			}
		}
	`,
})
export class ScrollToTopComponent {
	private readonly scrollService = inject(ScrollService);
	private readonly deviceService = inject(DeviceService);
	protected readonly visibility = toSignal(
		combineLatest([this.scrollService.position, toObservable(this.deviceService.height)]).pipe(
			map(([scroll, height]) => scroll.y > height / 2),
			distinctUntilChanged(),
		),
	);

	protected scrollToTop(): void {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}
}
