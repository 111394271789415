import { ChangeDetectionStrategy, Component, HostListener, ViewEncapsulation, signal } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'app-scroll-progress',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './scroll-progress.component.html',
	styleUrl: './scroll-progress.component.scss',
	encapsulation: ViewEncapsulation.Emulated,
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'[style.position]': '"fixed"',
		'[style.background-color]': '"currentcolor"',
		'[style.height.px]': '4',
		'[style.z-index]': '100',
		'[style.top]': '0',
		'[style.width.%]': 'width()',
	},
})
export class ScrollProgressComponent {
	protected width = signal(0);

	@HostListener('document:scroll', ['$event'])
	onScroll(): void {
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
		const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight || 0;
		const clientHeight = document.documentElement.clientHeight || window.innerHeight || 0;

		const scrolled = (scrollTop / (scrollHeight - clientHeight)) * 100;
		this.width.set(Math.min(Math.max(scrolled, 0), 100));
	}
}
