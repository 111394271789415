
<div class="wrapper">

	<button
		tuiButton
		appearance="show-hide"
		[iconStart]="isVisible() ? '@tui.chevron-right' : '@tui.chevron-left'"
		(mousemove)="0"

		(click)="setIsVisible()"
	></button>
	<div class="canvas-wrapper">
			<canvas [@showHide]="isVisible() ? 'show' : 'hide'" class="container" #container (click)="onClick()"></canvas>
			<div class="buttons"  [@showHideSecond]="isVisible() ? 'show' : 'hide'">
				<button
					(click)="playPause()"
					tuiButton
					appearance="audio-ctrl"
					[iconStart]="playing() ? '@tui.pause' : '@tui.play'"
				></button>
				<button
					(click)="stop()"
					tuiButton
					appearance="audio-ctrl"
					iconStart="@tui.square"
				></button>
			</div>
	</div>
</div>

