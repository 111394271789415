import { ChangeDetectionStrategy, Component, signal, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiButton } from '@taiga-ui/core';
import { RegisterFormComponent } from '@core/components/auth/register-form.component';
import { LoginFormComponent } from '@core/components/auth/login-form.component';
import { animate, style, transition, trigger } from '@angular/animations';

type AuthType = 'login' | 'register';

@Component({
	selector: 'app-auth-dialog',
	standalone: true,
	imports: [CommonModule, TuiButton, RegisterFormComponent, LoginFormComponent],
	templateUrl: './auth-dialog.component.html',
	styleUrl: './auth-dialog.component.scss',
	encapsulation: ViewEncapsulation.Emulated,
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('register', [
			// Анимация входа (enter) справа налево
			transition(':enter', [
				style({ transform: 'translateX(100%)', opacity: 0 }), // Начальное состояние: вне экрана справа
				// eslint-disable-next-line max-len
				animate('300ms ease-out', style({ transform: 'translateX(0)', opacity: 1 })), // Конечное состояние: на месте
			]),

			// Анимация выхода (leave) справа налево
			transition(':leave', [
				style({ transform: 'translateX(0)', opacity: 1 }), // Начальное состояние: на месте
				// eslint-disable-next-line max-len
				animate('300ms ease-in', style({ transform: 'translateX(-100%)', opacity: 0 })), // Конечное состояние: вне экрана слева
			]),
		]),
		trigger('login', [
			// Анимация входа (enter) справа налево
			transition(':enter', [
				style({ transform: 'translateX(-100%)', opacity: 0 }), // Начальное состояние: вне экрана справа
				// eslint-disable-next-line max-len
				animate('300ms ease-out', style({ transform: 'translateX(0)', opacity: 1 })), // Конечное состояние: на месте
			]),

			// Анимация выхода (leave) справа налево
			transition(':leave', [
				style({ transform: 'translateX(0)', opacity: 1 }), // Начальное состояние: на месте
				// eslint-disable-next-line max-len
				animate('300ms ease-in', style({ transform: 'translateX(-100%)', opacity: 0 })), // Конечное состояние: вне экрана слева
			]),
		]),
	],
})
export class AuthDialogComponent {
	protected readonly authType = signal<AuthType>('register');
}
