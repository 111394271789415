import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as CookieConsent from 'vanilla-cookieconsent';
import { TuiButton } from '@taiga-ui/core';

@Component({
	selector: 'app-cookie-consent-button',
	standalone: true,
	imports: [CommonModule, TuiButton],
	templateUrl: './cookie-consent-open.component.html',
	styleUrl: './cookie-consent-open.component.scss',
	encapsulation: ViewEncapsulation.Emulated,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookieConsentOpenComponent {
	openConsentWindow(): void {
		CookieConsent.show(true);
	}
}
