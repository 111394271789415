<div class="wrapper">
	<h3>Registration</h3>

	<form action="" class="form" (ngSubmit)="register()">
		<fieldset #field>
			<tui-input
				[tuiTextfieldCleaner]="true"
				[tuiTextfieldAppearance]="'filled'"
				[formControl]="form.controls.email"
			>
				Email
			</tui-input>

			<tui-error
				[formControl]="form.controls.email"
				[error]="[] | tuiFieldError | async"
			></tui-error>
		</fieldset>

		<fieldset #field>
			<tui-input
				[tuiTextfieldCleaner]="true"
				[tuiTextfieldAppearance]="'filled'"
				[formControl]="form.controls.username"
			>
				Username
			</tui-input>

			<tui-error
				[formControl]="form.controls.username"
				[error]="[] | tuiFieldError | async"
			></tui-error>
		</fieldset>

		<fieldset #field>
			<tui-input
				[tuiTextfieldCleaner]="true"
				[tuiTextfieldAppearance]="'filled'"
				[formControl]="form.controls.password"
			>
				Password
			</tui-input>

			<tui-error
				[formControl]="form.controls.password"
				[error]="[] | tuiFieldError | async"
			></tui-error>
		</fieldset>

		<fieldset #field>

			<button
				tuiButton
				[disabled]="form.invalid"
				[loading]="form.processing()"
			>
				Register
			</button>
		</fieldset>
	</form>
</div>
