import { Component } from '@angular/core';
import { TuiButton } from '@taiga-ui/core';

@Component({
	selector: 'app-print-button',
	standalone: true,
	template: '<button class="fixed-button" tuiButton (click)="print()" appearance="flat">Print</button>',
	styleUrl: 'print.component.scss',
	imports: [TuiButton],
})
export class PrintComponent {
	protected print(): void {
		print();
	}
}
