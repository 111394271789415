import { VertexBuffer } from "../Buffers/buffer.js";
import { AbstractMesh } from "../Meshes/abstractMesh.js";
import { LinesMesh, InstancedLinesMesh } from "../Meshes/linesMesh.js";
import { Vector3, TmpVectors } from "../Maths/math.vector.js";
import { Material } from "../Materials/material.js";
import { ShaderMaterial } from "../Materials/shaderMaterial.js";
import { Camera } from "../Cameras/camera.js";
import { SmartArray } from "../Misc/smartArray.js";
import { DrawWrapper } from "../Materials/drawWrapper.js";
AbstractMesh.prototype.disableEdgesRendering = function () {
  if (this._edgesRenderer) {
    this._edgesRenderer.dispose();
    this._edgesRenderer = null;
  }
  return this;
};
AbstractMesh.prototype.enableEdgesRendering = function (epsilon = 0.95, checkVerticesInsteadOfIndices = false, options) {
  this.disableEdgesRendering();
  this._edgesRenderer = new EdgesRenderer(this, epsilon, checkVerticesInsteadOfIndices, true, options);
  return this;
};
Object.defineProperty(AbstractMesh.prototype, "edgesRenderer", {
  get: function () {
    return this._edgesRenderer;
  },
  enumerable: true,
  configurable: true
});
LinesMesh.prototype.enableEdgesRendering = function (epsilon = 0.95, checkVerticesInsteadOfIndices = false) {
  this.disableEdgesRendering();
  this._edgesRenderer = new LineEdgesRenderer(this, epsilon, checkVerticesInsteadOfIndices);
  return this;
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
InstancedLinesMesh.prototype.enableEdgesRendering = function (epsilon = 0.95, checkVerticesInsteadOfIndices = false) {
  LinesMesh.prototype.enableEdgesRendering.apply(this, arguments);
  return this;
};
/**
 * FaceAdjacencies Helper class to generate edges
 */
class FaceAdjacencies {
  constructor() {
    this.edges = [];
    this.edgesConnectedCount = 0;
  }
}
/**
 * This class is used to generate edges of the mesh that could then easily be rendered in a scene.
 */
export class EdgesRenderer {
  /** Gets the vertices generated by the edge renderer */
  get linesPositions() {
    return this._linesPositions;
  }
  /** Gets the normals generated by the edge renderer */
  get linesNormals() {
    return this._linesNormals;
  }
  /** Gets the indices generated by the edge renderer */
  get linesIndices() {
    return this._linesIndices;
  }
  /**
   * Gets or sets the shader used to draw the lines
   */
  get lineShader() {
    return this._lineShader;
  }
  set lineShader(shader) {
    this._lineShader = shader;
  }
  static _GetShader(scene, shaderLanguage) {
    if (!scene._edgeRenderLineShader) {
      const shader = new ShaderMaterial("lineShader", scene, "line", {
        attributes: ["position", "normal"],
        uniforms: ["world", "viewProjection", "color", "width", "aspectRatio"],
        uniformBuffers: ["Scene", "Mesh"],
        shaderLanguage: shaderLanguage,
        extraInitializationsAsync: async () => {
          if (shaderLanguage === 1 /* ShaderLanguage.WGSL */) {
            await Promise.all([import("../ShadersWGSL/line.vertex.js"), import("../ShadersWGSL/line.fragment.js")]);
          } else {
            await Promise.all([import("../Shaders/line.vertex.js"), import("../Shaders/line.fragment.js")]);
          }
        }
      }, false);
      shader.disableDepthWrite = true;
      shader.backFaceCulling = false;
      shader.checkReadyOnEveryCall = scene.getEngine().isWebGPU;
      scene._edgeRenderLineShader = shader;
      scene.onDisposeObservable.add(() => {
        scene._edgeRenderLineShader.dispose();
        scene._edgeRenderLineShader = null;
      });
    }
    return scene._edgeRenderLineShader;
  }
  /**
   * Gets the shader language used.
   */
  get shaderLanguage() {
    return this._shaderLanguage;
  }
  /**
   * Creates an instance of the EdgesRenderer. It is primarily use to display edges of a mesh.
   * Beware when you use this class with complex objects as the adjacencies computation can be really long
   * @param  source Mesh used to create edges
   * @param  epsilon sum of angles in adjacency to check for edge
   * @param  checkVerticesInsteadOfIndices bases the edges detection on vertices vs indices. Note that this parameter is not used if options.useAlternateEdgeFinder = true
   * @param  generateEdgesLines - should generate Lines or only prepare resources.
   * @param  options The options to apply when generating the edges
   */
  constructor(source, epsilon = 0.95, checkVerticesInsteadOfIndices = false, generateEdgesLines = true, options) {
    /**
     * Define the size of the edges with an orthographic camera
     */
    this.edgesWidthScalerForOrthographic = 1000.0;
    /**
     * Define the size of the edges with a perspective camera
     */
    this.edgesWidthScalerForPerspective = 50.0;
    this._linesPositions = new Array();
    this._linesNormals = new Array();
    this._linesIndices = new Array();
    this._buffers = {};
    this._buffersForInstances = {};
    this._checkVerticesInsteadOfIndices = false;
    /** Gets or sets a boolean indicating if the edgesRenderer is active */
    this.isEnabled = true;
    /**
     * List of instances to render in case the source mesh has instances
     */
    this.customInstances = new SmartArray(32);
    /** Shader language used*/
    this._shaderLanguage = 0 /* ShaderLanguage.GLSL */;
    this._source = source;
    this._checkVerticesInsteadOfIndices = checkVerticesInsteadOfIndices;
    this._options = options ?? null;
    this._epsilon = epsilon;
    const engine = this._source.getScene().getEngine();
    if (engine.isWebGPU) {
      this._drawWrapper = new DrawWrapper(engine);
      this._shaderLanguage = 1 /* ShaderLanguage.WGSL */;
    }
    this._prepareRessources();
    if (generateEdgesLines) {
      if (options?.useAlternateEdgeFinder ?? true) {
        this._generateEdgesLinesAlternate();
      } else {
        this._generateEdgesLines();
      }
    }
    this._meshRebuildObserver = this._source.onRebuildObservable.add(() => {
      this._rebuild();
    });
    this._meshDisposeObserver = this._source.onDisposeObservable.add(() => {
      this.dispose();
    });
  }
  _prepareRessources() {
    if (this._lineShader) {
      return;
    }
    this._lineShader = EdgesRenderer._GetShader(this._source.getScene(), this._shaderLanguage);
  }
  /** @internal */
  _rebuild() {
    let buffer = this._buffers[VertexBuffer.PositionKind];
    if (buffer) {
      buffer._rebuild();
    }
    buffer = this._buffers[VertexBuffer.NormalKind];
    if (buffer) {
      buffer._rebuild();
    }
    const scene = this._source.getScene();
    const engine = scene.getEngine();
    this._ib = engine.createIndexBuffer(this._linesIndices);
  }
  /**
   * Releases the required resources for the edges renderer
   */
  dispose() {
    this._source.onRebuildObservable.remove(this._meshRebuildObserver);
    this._source.onDisposeObservable.remove(this._meshDisposeObserver);
    let buffer = this._buffers[VertexBuffer.PositionKind];
    if (buffer) {
      buffer.dispose();
      this._buffers[VertexBuffer.PositionKind] = null;
    }
    buffer = this._buffers[VertexBuffer.NormalKind];
    if (buffer) {
      buffer.dispose();
      this._buffers[VertexBuffer.NormalKind] = null;
    }
    if (this._ib) {
      this._source.getScene().getEngine()._releaseBuffer(this._ib);
    }
    this._drawWrapper?.dispose();
  }
  _processEdgeForAdjacencies(pa, pb, p0, p1, p2) {
    if (pa === p0 && pb === p1 || pa === p1 && pb === p0) {
      return 0;
    }
    if (pa === p1 && pb === p2 || pa === p2 && pb === p1) {
      return 1;
    }
    if (pa === p2 && pb === p0 || pa === p0 && pb === p2) {
      return 2;
    }
    return -1;
  }
  _processEdgeForAdjacenciesWithVertices(pa, pb, p0, p1, p2) {
    const eps = 1e-10;
    if (pa.equalsWithEpsilon(p0, eps) && pb.equalsWithEpsilon(p1, eps) || pa.equalsWithEpsilon(p1, eps) && pb.equalsWithEpsilon(p0, eps)) {
      return 0;
    }
    if (pa.equalsWithEpsilon(p1, eps) && pb.equalsWithEpsilon(p2, eps) || pa.equalsWithEpsilon(p2, eps) && pb.equalsWithEpsilon(p1, eps)) {
      return 1;
    }
    if (pa.equalsWithEpsilon(p2, eps) && pb.equalsWithEpsilon(p0, eps) || pa.equalsWithEpsilon(p0, eps) && pb.equalsWithEpsilon(p2, eps)) {
      return 2;
    }
    return -1;
  }
  /**
   * Checks if the pair of p0 and p1 is en edge
   * @param faceIndex
   * @param edge
   * @param faceNormals
   * @param  p0
   * @param  p1
   * @private
   */
  _checkEdge(faceIndex, edge, faceNormals, p0, p1) {
    let needToCreateLine;
    if (edge === undefined) {
      needToCreateLine = true;
    } else {
      const dotProduct = Vector3.Dot(faceNormals[faceIndex], faceNormals[edge]);
      needToCreateLine = dotProduct < this._epsilon;
    }
    if (needToCreateLine) {
      this.createLine(p0, p1, this._linesPositions.length / 3);
    }
  }
  /**
   * push line into the position, normal and index buffer
   * @param p0
   * @param p1
   * @param offset
   * @protected
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  createLine(p0, p1, offset) {
    // Positions
    this._linesPositions.push(p0.x, p0.y, p0.z, p0.x, p0.y, p0.z, p1.x, p1.y, p1.z, p1.x, p1.y, p1.z);
    // Normals
    this._linesNormals.push(p1.x, p1.y, p1.z, -1, p1.x, p1.y, p1.z, 1, p0.x, p0.y, p0.z, -1, p0.x, p0.y, p0.z, 1);
    // Indices
    this._linesIndices.push(offset, offset + 1, offset + 2, offset, offset + 2, offset + 3);
  }
  /**
   * See https://playground.babylonjs.com/#R3JR6V#1 for a visual display of the algorithm
   * @param edgePoints
   * @param indexTriangle
   * @param indices
   * @param remapVertexIndices
   */
  _tessellateTriangle(edgePoints, indexTriangle, indices, remapVertexIndices) {
    const makePointList = (edgePoints, pointIndices, firstIndex) => {
      if (firstIndex >= 0) {
        pointIndices.push(firstIndex);
      }
      for (let i = 0; i < edgePoints.length; ++i) {
        pointIndices.push(edgePoints[i][0]);
      }
    };
    let startEdge = 0;
    if (edgePoints[1].length >= edgePoints[0].length && edgePoints[1].length >= edgePoints[2].length) {
      startEdge = 1;
    } else if (edgePoints[2].length >= edgePoints[0].length && edgePoints[2].length >= edgePoints[1].length) {
      startEdge = 2;
    }
    for (let e = 0; e < 3; ++e) {
      if (e === startEdge) {
        edgePoints[e].sort((a, b) => a[1] < b[1] ? -1 : a[1] > b[1] ? 1 : 0);
      } else {
        edgePoints[e].sort((a, b) => a[1] > b[1] ? -1 : a[1] < b[1] ? 1 : 0);
      }
    }
    const mainPointIndices = [],
      otherPointIndices = [];
    makePointList(edgePoints[startEdge], mainPointIndices, -1);
    const numMainPoints = mainPointIndices.length;
    for (let i = startEdge + 2; i >= startEdge + 1; --i) {
      makePointList(edgePoints[i % 3], otherPointIndices, i !== startEdge + 2 ? remapVertexIndices[indices[indexTriangle + (i + 1) % 3]] : -1);
    }
    const numOtherPoints = otherPointIndices.length;
    const idxMain = 0;
    const idxOther = 0;
    indices.push(remapVertexIndices[indices[indexTriangle + startEdge]], mainPointIndices[0], otherPointIndices[0]);
    indices.push(remapVertexIndices[indices[indexTriangle + (startEdge + 1) % 3]], otherPointIndices[numOtherPoints - 1], mainPointIndices[numMainPoints - 1]);
    const bucketIsMain = numMainPoints <= numOtherPoints;
    const bucketStep = bucketIsMain ? numMainPoints : numOtherPoints;
    const bucketLimit = bucketIsMain ? numOtherPoints : numMainPoints;
    const bucketIdxLimit = bucketIsMain ? numMainPoints - 1 : numOtherPoints - 1;
    const winding = bucketIsMain ? 0 : 1;
    let numTris = numMainPoints + numOtherPoints - 2;
    let bucketIdx = bucketIsMain ? idxMain : idxOther;
    let nbucketIdx = bucketIsMain ? idxOther : idxMain;
    const bucketPoints = bucketIsMain ? mainPointIndices : otherPointIndices;
    const nbucketPoints = bucketIsMain ? otherPointIndices : mainPointIndices;
    let bucket = 0;
    while (numTris-- > 0) {
      if (winding) {
        indices.push(bucketPoints[bucketIdx], nbucketPoints[nbucketIdx]);
      } else {
        indices.push(nbucketPoints[nbucketIdx], bucketPoints[bucketIdx]);
      }
      bucket += bucketStep;
      let lastIdx;
      if (bucket >= bucketLimit && bucketIdx < bucketIdxLimit) {
        lastIdx = bucketPoints[++bucketIdx];
        bucket -= bucketLimit;
      } else {
        lastIdx = nbucketPoints[++nbucketIdx];
      }
      indices.push(lastIdx);
    }
    indices[indexTriangle + 0] = indices[indices.length - 3];
    indices[indexTriangle + 1] = indices[indices.length - 2];
    indices[indexTriangle + 2] = indices[indices.length - 1];
    indices.length = indices.length - 3;
  }
  _generateEdgesLinesAlternate() {
    const positions = this._source.getVerticesData(VertexBuffer.PositionKind);
    let indices = this._source.getIndices();
    if (!indices || !positions) {
      return;
    }
    if (!Array.isArray(indices)) {
      indices = Array.from(indices);
    }
    /**
     * Find all vertices that are at the same location (with an epsilon) and remapp them on the same vertex
     */
    const useFastVertexMerger = this._options?.useFastVertexMerger ?? true;
    const epsVertexMerge = useFastVertexMerger ? Math.round(-Math.log(this._options?.epsilonVertexMerge ?? 1e-6) / Math.log(10)) : this._options?.epsilonVertexMerge ?? 1e-6;
    const remapVertexIndices = [];
    const uniquePositions = []; // list of unique index of vertices - needed for tessellation
    if (useFastVertexMerger) {
      const mapVertices = {};
      for (let v1 = 0; v1 < positions.length; v1 += 3) {
        const x1 = positions[v1 + 0],
          y1 = positions[v1 + 1],
          z1 = positions[v1 + 2];
        const key = x1.toFixed(epsVertexMerge) + "|" + y1.toFixed(epsVertexMerge) + "|" + z1.toFixed(epsVertexMerge);
        if (mapVertices[key] !== undefined) {
          remapVertexIndices.push(mapVertices[key]);
        } else {
          const idx = v1 / 3;
          mapVertices[key] = idx;
          remapVertexIndices.push(idx);
          uniquePositions.push(idx);
        }
      }
    } else {
      for (let v1 = 0; v1 < positions.length; v1 += 3) {
        const x1 = positions[v1 + 0],
          y1 = positions[v1 + 1],
          z1 = positions[v1 + 2];
        let found = false;
        for (let v2 = 0; v2 < v1 && !found; v2 += 3) {
          const x2 = positions[v2 + 0],
            y2 = positions[v2 + 1],
            z2 = positions[v2 + 2];
          if (Math.abs(x1 - x2) < epsVertexMerge && Math.abs(y1 - y2) < epsVertexMerge && Math.abs(z1 - z2) < epsVertexMerge) {
            remapVertexIndices.push(v2 / 3);
            found = true;
            break;
          }
        }
        if (!found) {
          remapVertexIndices.push(v1 / 3);
          uniquePositions.push(v1 / 3);
        }
      }
    }
    if (this._options?.applyTessellation) {
      /**
       * Tessellate triangles if necessary:
       *
       *               A
       *               +
       *               |\
       *               | \
       *               |  \
       *             E +   \
       *              /|    \
       *             / |     \
       *            /  |      \
       *           +---+-------+ B
       *           D   C
       *
       * For the edges to be rendered correctly, the ABC triangle has to be split into ABE and BCE, else AC is considered to be an edge, whereas only AE should be.
       *
       * The tessellation process looks for the vertices like E that are in-between two other vertices making of an edge and create new triangles as necessary
       */
      // First step: collect the triangles to tessellate
      const epsVertexAligned = this._options?.epsilonVertexAligned ?? 1e-6;
      const mustTesselate = []; // liste of triangles that must be tessellated
      for (let index = 0; index < indices.length; index += 3) {
        // loop over all triangles
        let triangleToTessellate;
        for (let i = 0; i < 3; ++i) {
          // loop over the 3 edges of the triangle
          const p0Index = remapVertexIndices[indices[index + i]];
          const p1Index = remapVertexIndices[indices[index + (i + 1) % 3]];
          const p2Index = remapVertexIndices[indices[index + (i + 2) % 3]];
          if (p0Index === p1Index) {
            continue;
          } // degenerated triangle - don't process
          const p0x = positions[p0Index * 3 + 0],
            p0y = positions[p0Index * 3 + 1],
            p0z = positions[p0Index * 3 + 2];
          const p1x = positions[p1Index * 3 + 0],
            p1y = positions[p1Index * 3 + 1],
            p1z = positions[p1Index * 3 + 2];
          const p0p1 = Math.sqrt((p1x - p0x) * (p1x - p0x) + (p1y - p0y) * (p1y - p0y) + (p1z - p0z) * (p1z - p0z));
          for (let v = 0; v < uniquePositions.length - 1; v++) {
            // loop over all (unique) vertices and look for the ones that would be in-between p0 and p1
            const vIndex = uniquePositions[v];
            if (vIndex === p0Index || vIndex === p1Index || vIndex === p2Index) {
              continue;
            } // don't handle the vertex if it is a vertex of the current triangle
            const x = positions[vIndex * 3 + 0],
              y = positions[vIndex * 3 + 1],
              z = positions[vIndex * 3 + 2];
            const p0p = Math.sqrt((x - p0x) * (x - p0x) + (y - p0y) * (y - p0y) + (z - p0z) * (z - p0z));
            const pp1 = Math.sqrt((x - p1x) * (x - p1x) + (y - p1y) * (y - p1y) + (z - p1z) * (z - p1z));
            if (Math.abs(p0p + pp1 - p0p1) < epsVertexAligned) {
              // vertices are aligned and p in-between p0 and p1 if distance(p0, p) + distance (p, p1) ~ distance(p0, p1)
              if (!triangleToTessellate) {
                triangleToTessellate = {
                  index: index,
                  edgesPoints: [[], [], []]
                };
                mustTesselate.push(triangleToTessellate);
              }
              triangleToTessellate.edgesPoints[i].push([vIndex, p0p]);
            }
          }
        }
      }
      // Second step: tesselate the triangles
      for (let t = 0; t < mustTesselate.length; ++t) {
        const triangle = mustTesselate[t];
        this._tessellateTriangle(triangle.edgesPoints, triangle.index, indices, remapVertexIndices);
      }
      mustTesselate.length = 0;
    }
    /**
     * Collect the edges to render
     */
    const edges = {};
    for (let index = 0; index < indices.length; index += 3) {
      let faceNormal;
      for (let i = 0; i < 3; ++i) {
        let p0Index = remapVertexIndices[indices[index + i]];
        let p1Index = remapVertexIndices[indices[index + (i + 1) % 3]];
        const p2Index = remapVertexIndices[indices[index + (i + 2) % 3]];
        if (p0Index === p1Index || (p0Index === p2Index || p1Index === p2Index) && this._options?.removeDegeneratedTriangles) {
          continue;
        }
        TmpVectors.Vector3[0].copyFromFloats(positions[p0Index * 3 + 0], positions[p0Index * 3 + 1], positions[p0Index * 3 + 2]);
        TmpVectors.Vector3[1].copyFromFloats(positions[p1Index * 3 + 0], positions[p1Index * 3 + 1], positions[p1Index * 3 + 2]);
        TmpVectors.Vector3[2].copyFromFloats(positions[p2Index * 3 + 0], positions[p2Index * 3 + 1], positions[p2Index * 3 + 2]);
        if (!faceNormal) {
          TmpVectors.Vector3[1].subtractToRef(TmpVectors.Vector3[0], TmpVectors.Vector3[3]);
          TmpVectors.Vector3[2].subtractToRef(TmpVectors.Vector3[1], TmpVectors.Vector3[4]);
          faceNormal = Vector3.Cross(TmpVectors.Vector3[3], TmpVectors.Vector3[4]);
          faceNormal.normalize();
        }
        if (p0Index > p1Index) {
          const tmp = p0Index;
          p0Index = p1Index;
          p1Index = tmp;
        }
        const key = p0Index + "_" + p1Index;
        const ei = edges[key];
        if (ei) {
          if (!ei.done) {
            const dotProduct = Vector3.Dot(faceNormal, ei.normal);
            if (dotProduct < this._epsilon) {
              this.createLine(TmpVectors.Vector3[0], TmpVectors.Vector3[1], this._linesPositions.length / 3);
            }
            ei.done = true;
          }
        } else {
          edges[key] = {
            normal: faceNormal,
            done: false,
            index: index,
            i: i
          };
        }
      }
    }
    for (const key in edges) {
      const ei = edges[key];
      if (!ei.done) {
        // Orphaned edge - we must display it
        const p0Index = remapVertexIndices[indices[ei.index + ei.i]];
        const p1Index = remapVertexIndices[indices[ei.index + (ei.i + 1) % 3]];
        TmpVectors.Vector3[0].copyFromFloats(positions[p0Index * 3 + 0], positions[p0Index * 3 + 1], positions[p0Index * 3 + 2]);
        TmpVectors.Vector3[1].copyFromFloats(positions[p1Index * 3 + 0], positions[p1Index * 3 + 1], positions[p1Index * 3 + 2]);
        this.createLine(TmpVectors.Vector3[0], TmpVectors.Vector3[1], this._linesPositions.length / 3);
      }
    }
    /**
     * Merge into a single mesh
     */
    const engine = this._source.getScene().getEngine();
    this._buffers[VertexBuffer.PositionKind] = new VertexBuffer(engine, this._linesPositions, VertexBuffer.PositionKind, false);
    this._buffers[VertexBuffer.NormalKind] = new VertexBuffer(engine, this._linesNormals, VertexBuffer.NormalKind, false, false, 4);
    this._buffersForInstances[VertexBuffer.PositionKind] = this._buffers[VertexBuffer.PositionKind];
    this._buffersForInstances[VertexBuffer.NormalKind] = this._buffers[VertexBuffer.NormalKind];
    this._ib = engine.createIndexBuffer(this._linesIndices);
    this._indicesCount = this._linesIndices.length;
  }
  /**
   * Generates lines edges from adjacencjes
   * @private
   */
  _generateEdgesLines() {
    const positions = this._source.getVerticesData(VertexBuffer.PositionKind);
    const indices = this._source.getIndices();
    if (!indices || !positions) {
      return;
    }
    // First let's find adjacencies
    const adjacencies = [];
    const faceNormals = [];
    let index;
    let faceAdjacencies;
    // Prepare faces
    for (index = 0; index < indices.length; index += 3) {
      faceAdjacencies = new FaceAdjacencies();
      const p0Index = indices[index];
      const p1Index = indices[index + 1];
      const p2Index = indices[index + 2];
      faceAdjacencies.p0 = new Vector3(positions[p0Index * 3], positions[p0Index * 3 + 1], positions[p0Index * 3 + 2]);
      faceAdjacencies.p1 = new Vector3(positions[p1Index * 3], positions[p1Index * 3 + 1], positions[p1Index * 3 + 2]);
      faceAdjacencies.p2 = new Vector3(positions[p2Index * 3], positions[p2Index * 3 + 1], positions[p2Index * 3 + 2]);
      const faceNormal = Vector3.Cross(faceAdjacencies.p1.subtract(faceAdjacencies.p0), faceAdjacencies.p2.subtract(faceAdjacencies.p1));
      faceNormal.normalize();
      faceNormals.push(faceNormal);
      adjacencies.push(faceAdjacencies);
    }
    // Scan
    for (index = 0; index < adjacencies.length; index++) {
      faceAdjacencies = adjacencies[index];
      for (let otherIndex = index + 1; otherIndex < adjacencies.length; otherIndex++) {
        const otherFaceAdjacencies = adjacencies[otherIndex];
        if (faceAdjacencies.edgesConnectedCount === 3) {
          // Full
          break;
        }
        if (otherFaceAdjacencies.edgesConnectedCount === 3) {
          // Full
          continue;
        }
        const otherP0 = indices[otherIndex * 3];
        const otherP1 = indices[otherIndex * 3 + 1];
        const otherP2 = indices[otherIndex * 3 + 2];
        for (let edgeIndex = 0; edgeIndex < 3; edgeIndex++) {
          let otherEdgeIndex = 0;
          if (faceAdjacencies.edges[edgeIndex] !== undefined) {
            continue;
          }
          switch (edgeIndex) {
            case 0:
              if (this._checkVerticesInsteadOfIndices) {
                otherEdgeIndex = this._processEdgeForAdjacenciesWithVertices(faceAdjacencies.p0, faceAdjacencies.p1, otherFaceAdjacencies.p0, otherFaceAdjacencies.p1, otherFaceAdjacencies.p2);
              } else {
                otherEdgeIndex = this._processEdgeForAdjacencies(indices[index * 3], indices[index * 3 + 1], otherP0, otherP1, otherP2);
              }
              break;
            case 1:
              if (this._checkVerticesInsteadOfIndices) {
                otherEdgeIndex = this._processEdgeForAdjacenciesWithVertices(faceAdjacencies.p1, faceAdjacencies.p2, otherFaceAdjacencies.p0, otherFaceAdjacencies.p1, otherFaceAdjacencies.p2);
              } else {
                otherEdgeIndex = this._processEdgeForAdjacencies(indices[index * 3 + 1], indices[index * 3 + 2], otherP0, otherP1, otherP2);
              }
              break;
            case 2:
              if (this._checkVerticesInsteadOfIndices) {
                otherEdgeIndex = this._processEdgeForAdjacenciesWithVertices(faceAdjacencies.p2, faceAdjacencies.p0, otherFaceAdjacencies.p0, otherFaceAdjacencies.p1, otherFaceAdjacencies.p2);
              } else {
                otherEdgeIndex = this._processEdgeForAdjacencies(indices[index * 3 + 2], indices[index * 3], otherP0, otherP1, otherP2);
              }
              break;
          }
          if (otherEdgeIndex === -1) {
            continue;
          }
          faceAdjacencies.edges[edgeIndex] = otherIndex;
          otherFaceAdjacencies.edges[otherEdgeIndex] = index;
          faceAdjacencies.edgesConnectedCount++;
          otherFaceAdjacencies.edgesConnectedCount++;
          if (faceAdjacencies.edgesConnectedCount === 3) {
            break;
          }
        }
      }
    }
    // Create lines
    for (index = 0; index < adjacencies.length; index++) {
      // We need a line when a face has no adjacency on a specific edge or if all the adjacencies has an angle greater than epsilon
      const current = adjacencies[index];
      this._checkEdge(index, current.edges[0], faceNormals, current.p0, current.p1);
      this._checkEdge(index, current.edges[1], faceNormals, current.p1, current.p2);
      this._checkEdge(index, current.edges[2], faceNormals, current.p2, current.p0);
    }
    // Merge into a single mesh
    const engine = this._source.getScene().getEngine();
    this._buffers[VertexBuffer.PositionKind] = new VertexBuffer(engine, this._linesPositions, VertexBuffer.PositionKind, false);
    this._buffers[VertexBuffer.NormalKind] = new VertexBuffer(engine, this._linesNormals, VertexBuffer.NormalKind, false, false, 4);
    this._buffersForInstances[VertexBuffer.PositionKind] = this._buffers[VertexBuffer.PositionKind];
    this._buffersForInstances[VertexBuffer.NormalKind] = this._buffers[VertexBuffer.NormalKind];
    this._ib = engine.createIndexBuffer(this._linesIndices);
    this._indicesCount = this._linesIndices.length;
  }
  /**
   * Checks whether or not the edges renderer is ready to render.
   * @returns true if ready, otherwise false.
   */
  isReady() {
    return this._lineShader.isReady(this._source, this._source.hasInstances && this.customInstances.length > 0 || this._source.hasThinInstances);
  }
  /**
   * Renders the edges of the attached mesh,
   */
  render() {
    const scene = this._source.getScene();
    const currentDrawWrapper = this._lineShader._getDrawWrapper();
    if (this._drawWrapper) {
      this._lineShader._setDrawWrapper(this._drawWrapper);
    }
    if (!this.isReady() || !scene.activeCamera) {
      this._lineShader._setDrawWrapper(currentDrawWrapper);
      return;
    }
    const hasInstances = this._source.hasInstances && this.customInstances.length > 0;
    const useBuffersWithInstances = hasInstances || this._source.hasThinInstances;
    let instanceCount = 0;
    if (useBuffersWithInstances) {
      this._buffersForInstances["world0"] = this._source.getVertexBuffer("world0");
      this._buffersForInstances["world1"] = this._source.getVertexBuffer("world1");
      this._buffersForInstances["world2"] = this._source.getVertexBuffer("world2");
      this._buffersForInstances["world3"] = this._source.getVertexBuffer("world3");
      if (hasInstances) {
        const instanceStorage = this._source._instanceDataStorage;
        instanceCount = this.customInstances.length;
        if (!instanceStorage.instancesData) {
          if (!this._source.getScene()._activeMeshesFrozen) {
            this.customInstances.reset();
          }
          return;
        }
        if (!instanceStorage.isFrozen) {
          let offset = 0;
          for (let i = 0; i < instanceCount; ++i) {
            this.customInstances.data[i].copyToArray(instanceStorage.instancesData, offset);
            offset += 16;
          }
          instanceStorage.instancesBuffer.updateDirectly(instanceStorage.instancesData, 0, instanceCount);
        }
      } else {
        instanceCount = this._source.thinInstanceCount;
      }
    }
    const engine = scene.getEngine();
    this._lineShader._preBind();
    if (this._source.edgesColor.a !== 1) {
      engine.setAlphaMode(2);
    } else {
      engine.setAlphaMode(0);
    }
    // VBOs
    engine.bindBuffers(useBuffersWithInstances ? this._buffersForInstances : this._buffers, this._ib, this._lineShader.getEffect());
    scene.resetCachedMaterial();
    this._lineShader.setColor4("color", this._source.edgesColor);
    if (scene.activeCamera.mode === Camera.ORTHOGRAPHIC_CAMERA) {
      this._lineShader.setFloat("width", this._source.edgesWidth / this.edgesWidthScalerForOrthographic);
    } else {
      this._lineShader.setFloat("width", this._source.edgesWidth / this.edgesWidthScalerForPerspective);
    }
    this._lineShader.setFloat("aspectRatio", engine.getAspectRatio(scene.activeCamera));
    this._lineShader.bind(this._source.getWorldMatrix(), this._source);
    // Draw order
    engine.drawElementsType(Material.TriangleFillMode, 0, this._indicesCount, instanceCount);
    this._lineShader.unbind();
    if (useBuffersWithInstances) {
      engine.unbindInstanceAttributes();
    }
    if (!this._source.getScene()._activeMeshesFrozen) {
      this.customInstances.reset();
    }
    this._lineShader._setDrawWrapper(currentDrawWrapper);
  }
}
/**
 * LineEdgesRenderer for LineMeshes to remove unnecessary triangulation
 */
export class LineEdgesRenderer extends EdgesRenderer {
  /**
   * This constructor turns off auto generating edges line in Edges Renderer to make it here.
   * @param  source LineMesh used to generate edges
   * @param  epsilon not important (specified angle for edge detection)
   * @param  checkVerticesInsteadOfIndices not important for LineMesh
   */
  constructor(source, epsilon = 0.95, checkVerticesInsteadOfIndices = false) {
    super(source, epsilon, checkVerticesInsteadOfIndices, false);
    this._generateEdgesLines();
  }
  /**
   * Generate edges for each line in LinesMesh. Every Line should be rendered as edge.
   */
  _generateEdgesLines() {
    const positions = this._source.getVerticesData(VertexBuffer.PositionKind);
    const indices = this._source.getIndices();
    if (!indices || !positions) {
      return;
    }
    const p0 = TmpVectors.Vector3[0];
    const p1 = TmpVectors.Vector3[1];
    const len = indices.length - 1;
    for (let i = 0, offset = 0; i < len; i += 2, offset += 4) {
      Vector3.FromArrayToRef(positions, 3 * indices[i], p0);
      Vector3.FromArrayToRef(positions, 3 * indices[i + 1], p1);
      this.createLine(p0, p1, offset);
    }
    // Merge into a single mesh
    const engine = this._source.getScene().getEngine();
    this._buffers[VertexBuffer.PositionKind] = new VertexBuffer(engine, this._linesPositions, VertexBuffer.PositionKind, false);
    this._buffers[VertexBuffer.NormalKind] = new VertexBuffer(engine, this._linesNormals, VertexBuffer.NormalKind, false, false, 4);
    this._ib = engine.createIndexBuffer(this._linesIndices);
    this._indicesCount = this._linesIndices.length;
  }
}
