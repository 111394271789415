import type { LinesMesh, Mesh } from '@babylonjs/core';
import type { Tags } from '@ui/services';
import type { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import {
	ArcRotateCamera,
	Color3,
	Engine,
	HemisphericLight,
	MeshBuilder,
	Scene,
	StandardMaterial,
	Vector3,
} from '@babylonjs/core';
import { AdvancedDynamicTexture, Control, TextBlock } from '@babylonjs/gui';
import { AudioInfoParser } from '@ui/services';
import { DateTime } from 'luxon';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: null,
})
export class AudioPlaybackService {
	private readonly audioInfoParser = inject(AudioInfoParser);

	private engine!: Engine;
	private scene!: Scene;
	private analyser!: AnalyserNode;
	private audioContext!: AudioContext;
	private titleText!: TextBlock;
	private authorText!: TextBlock;
	private durationText!: TextBlock;
	private advancedTexture!: AdvancedDynamicTexture;
	audioElement!: HTMLAudioElement;
	private linesMeshes: LinesMesh[] = [];
	private baseCircle!: Mesh;
	private segmentCount = 128;

	play(): void {
		this.audioElement.play();
	}

	pause(): void {
		this.audioElement.pause();
	}

	stop(): void {
		this.audioElement.currentTime = 0;
	}

	loadScene(canvas: HTMLCanvasElement, radius: number): void {
		this.engine = new Engine(canvas, true);
		this.scene = new Scene(this.engine);

		const camera = new ArcRotateCamera('camera', Math.PI / 2, Math.PI / 2, 20, Vector3.Zero(), this.scene);
		camera.attachControl(canvas, true);

		// eslint-disable-next-line no-new
		new HemisphericLight('light', new Vector3(0, 1, 0), this.scene);

		this.createBaseCircle(radius);

		this.createRadialLines(radius);

		this.engine.runRenderLoop(() => {
			this.updateVisualizer(radius);
			this.scene.render();
		});
	}

	private createBaseCircle(radius: number): void {
		this.baseCircle = MeshBuilder.CreateDisc(
			'baseCircle',
			{ radius, tessellation: this.segmentCount, updatable: true },
			this.scene,
		);

		const material = new StandardMaterial('circleMaterial', this.scene);
		material.emissiveColor = new Color3(1, 2, 1);
		this.baseCircle.material = material;

		const borderRadius = radius + 0.15;
		const points: Vector3[] = [];

		for (let i = 0; i < this.segmentCount; i++) {
			const angle = (i / this.segmentCount) * 2 * Math.PI;
			const point = new Vector3(Math.cos(angle) * borderRadius, Math.sin(angle) * borderRadius, 0);
			points.push(point);
		}

		const borderLine = MeshBuilder.CreateLines('borderLine', { points }, this.scene);

		const borderMaterial = new StandardMaterial('borderMaterial', this.scene);
		borderMaterial.emissiveColor = new Color3(1, 0, 0);
		borderLine.alpha = 0.5;
		borderLine.material = borderMaterial;
	}

	private createRadialLines(radius: number): void {
		for (let i = 0; i < this.segmentCount; i++) {
			const angle = (i / this.segmentCount) * 2 * Math.PI;

			const start = new Vector3(Math.cos(angle) * radius, Math.sin(angle) * radius, 0);
			const end = new Vector3(Math.cos(angle) * (radius + 0.1), Math.sin(angle) * (radius + 0.1), 0);

			const line = MeshBuilder.CreateLines(`line-${i}`, { points: [start, end], updatable: true }, this.scene);

			const material = new StandardMaterial(`lineMaterial-${i}`, this.scene);
			material.emissiveColor = new Color3(1, 1, 1);
			line.color = new Color3(1, 0.5, 1);

			this.linesMeshes.push(line);
		}
	}

	initAudioProcessing(volume: number, url: string, isLooped: boolean): void {
		this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
		this.audioElement = new Audio(url);
		this.audioElement.loop = isLooped;
		this.audioElement.volume = volume;

		const source = this.audioContext.createMediaElementSource(this.audioElement);
		this.analyser = this.audioContext.createAnalyser();
		this.analyser.fftSize = 256;

		source.connect(this.analyser);
		this.analyser.connect(this.audioContext.destination);

		this.audioContext.resume();
		// this.audioElement.play();
	}

	initAudioContextAfterUserGesture(): void {
		if (!this.audioContext) {
			this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
		}

		if (this.audioContext.state === 'suspended') {
			this.audioContext.resume();
		}
	}

	private updateVisualizer(radius: number): void {
		const bufferLength = this.analyser.frequencyBinCount;
		const dataArray = new Uint8Array(bufferLength);
		this.analyser.getByteFrequencyData(dataArray);

		this.linesMeshes.forEach((line, index) => {
			const amplitude = dataArray[index % bufferLength] / 255;
			const angle = (index / this.segmentCount) * 2 * Math.PI;

			const start = new Vector3(Math.cos(angle) * radius, Math.sin(angle) * radius, 0);
			const end = new Vector3(
				Math.cos(angle) * (radius + amplitude * 2),
				Math.sin(angle) * (radius + amplitude * 2),
				0,
			);

			const points = [start, end];
			line = MeshBuilder.CreateLines(`line-${index}`, { points, instance: line }, this.scene);

			line.color = new Color3(amplitude, 1 - amplitude, 0.5);
		});
	}

	addTrackInfo(url: string): Observable<Tags | null> {
		if (this.audioElement) {
			return this.audioInfoParser.readTags(url, this.audioElement).pipe(
				map((info) => {
					this.addTextToCircle(info);
					return info;
				}),
			);
		}
		return of(null);
	}

	private addTextToCircle(info: Tags | null): void {
		if (!this.scene || !this.baseCircle) return;

		if (!this.advancedTexture) {
			this.advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI('UI', true, this.scene);

			this.titleText = new TextBlock();
			this.titleText.color = 'white';
			this.titleText.fontSize = 8;
			this.titleText.top = '-12px';
			this.titleText.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
			this.advancedTexture.addControl(this.titleText);

			this.authorText = new TextBlock();
			this.authorText.color = 'white';
			this.authorText.fontSize = 10;
			this.authorText.top = '0px';
			this.authorText.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
			this.advancedTexture.addControl(this.authorText);

			this.durationText = new TextBlock();
			this.durationText.color = 'white';
			this.durationText.fontSize = 10;
			this.durationText.top = '15px';
			this.durationText.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
			this.advancedTexture.addControl(this.durationText);
		}

		this.titleText.text = info?.title ?? 'unknown';
		this.authorText.text = info?.author ?? 'Unknown';

		this.durationText.text = DateTime.fromSeconds(info!.duration!).toFormat(
			info!.duration >= 3600000 ? 'hh:mm:ss' : 'mm:ss',
		);
	}
}
