import type { FrameworkInfoOutput } from '@graphql/types';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, ViewEncapsulation } from '@angular/core';
import { DateTime } from 'luxon';

@Component({
	selector: 'app-deprecation-clock',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './deprecation-clock.component.html',
	styleUrl: './deprecation-clock.component.scss',
	encapsulation: ViewEncapsulation.Emulated,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeprecationClockComponent {
	readonly frameworkInfo = input.required<FrameworkInfoOutput>(); // Начало фреймворка

	readonly progress = computed(() => {
		const now = DateTime.now();
		const date = DateTime.fromString(this.frameworkInfo().dateCreated, 'Y.M.D HH:mm:ss');

		const elapsedTime = now.diff(date, 'day').days;
		const totalTime = 365 * 24 * 60 * 60;

		const percentage = (elapsedTime / totalTime) * 100;
		const radius = 90;
		const circumference = 2 * Math.PI * radius; // Длина окружности
		return circumference - (percentage / 100) * circumference;
	});

	readonly formatedDate = computed(() => {
		return DateTime.fromString(this.frameworkInfo().dateCreated, 'Y.M.D HH:mm:ss');
	});
}
