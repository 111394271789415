import type { ElementRef, OnInit } from '@angular/core';
import type { BehaviorSubject } from 'rxjs';
import type { Drawer } from './tokens';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, viewChild, ViewEncapsulation } from '@angular/core';
import { RouterLinkActive } from '@angular/router';
import { TuiIcon } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT, PolymorpheusOutlet } from '@taiga-ui/polymorpheus';
import { UserAccountLinkComponent } from '@ui/components';
import { DRAWERS } from './tokens';

@Component({
	selector: 'app-drawer',
	standalone: true,
	imports: [CommonModule, PolymorpheusOutlet, TuiIcon, UserAccountLinkComponent],
	templateUrl: './drawer.component.html',
	styleUrl: './drawer.component.scss',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [RouterLinkActive],
})
export class DrawerComponent implements OnInit {
	private readonly drawerElement = viewChild<ElementRef<HTMLElement>>('drawer');

	constructor(
		@Inject(POLYMORPHEUS_CONTEXT) readonly context: Drawer<unknown>,
		@Inject(DRAWERS) private readonly drawersSubject: BehaviorSubject<Drawer<unknown>[]>,
	) {}

	ngOnInit(): void {
		this.drawerElement()?.nativeElement.classList.add('opened');
	}

	closeDrawer(): void {
		this.drawerElement()!.nativeElement.classList.remove('closed');
		this.drawersSubject.next([]);
	}
}
