<div class="flexible-container">
 <div class="container" [class.deprecated]="frameworkInfo().isDeprecated">

	<div class="header">
		<div class="name">{{frameworkInfo().name}}</div>
	</div>

	<div class="clock-container">
		<svg width="200" height="200" >
			<!-- Фон часов -->
			<circle class="clock-background" cx="100" cy="100" r="90"/>
			<!-- Прогресс -->
			<circle class="clock-progress" cx="100" cy="100" r="90" stroke-dasharray="565.48" [style.stroke-dashoffset]="progress()"/>
			<!-- Центр -->
			<circle class="center-circle" cx="100" cy="100" r="10"/>
		</svg>
	</div>

	<div class="footer">
		<div class="developer">
			<span>Developed by:</span>
			<span>
				{{frameworkInfo().developer}}
			</span>
		</div>

		<div class="date-created">
			<span>Creation date: </span>
			<span>{{formatedDate()}}</span>
		</div>
	</div>
 </div>
</div>
