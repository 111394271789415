import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'a[appCvButton], app-cv-button',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './cv-button.component.html',
	styleUrl: './cv-button.component.scss',
	encapsulation: ViewEncapsulation.Emulated,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CvButtonComponent {
	protected xlink = '/assets/cv/cv.svg';
}
