import { NodeMaterialBlock } from "../../nodeMaterialBlock.js";
import { NodeMaterialBlockConnectionPointTypes } from "../../Enums/nodeMaterialBlockConnectionPointTypes.js";
import { NodeMaterialBlockTargets } from "../../Enums/nodeMaterialBlockTargets.js";
import { RegisterClass } from "../../../../Misc/typeStore.js";
import { Texture } from "../../../Textures/texture.js";

/**
 * Base block used as input for post process
 */
export class CurrentScreenBlock extends NodeMaterialBlock {
  /**
   * Create a new CurrentScreenBlock
   * @param name defines the block name
   */
  constructor(name) {
    super(name, NodeMaterialBlockTargets.VertexAndFragment);
    this._samplerName = "textureSampler";
    /**
     * Gets or sets a boolean indicating if content needs to be converted to gamma space
     */
    this.convertToGammaSpace = false;
    /**
     * Gets or sets a boolean indicating if content needs to be converted to linear space
     */
    this.convertToLinearSpace = false;
    this._isUnique = false;
    this.registerInput("uv", NodeMaterialBlockConnectionPointTypes.AutoDetect, false, NodeMaterialBlockTargets.VertexAndFragment);
    this.registerOutput("rgba", NodeMaterialBlockConnectionPointTypes.Color4, NodeMaterialBlockTargets.Neutral);
    this.registerOutput("rgb", NodeMaterialBlockConnectionPointTypes.Color3, NodeMaterialBlockTargets.Neutral);
    this.registerOutput("r", NodeMaterialBlockConnectionPointTypes.Float, NodeMaterialBlockTargets.Neutral);
    this.registerOutput("g", NodeMaterialBlockConnectionPointTypes.Float, NodeMaterialBlockTargets.Neutral);
    this.registerOutput("b", NodeMaterialBlockConnectionPointTypes.Float, NodeMaterialBlockTargets.Neutral);
    this.registerOutput("a", NodeMaterialBlockConnectionPointTypes.Float, NodeMaterialBlockTargets.Neutral);
    this._inputs[0].addExcludedConnectionPointFromAllowedTypes(NodeMaterialBlockConnectionPointTypes.Vector2 | NodeMaterialBlockConnectionPointTypes.Vector3 | NodeMaterialBlockConnectionPointTypes.Vector4);
    this._inputs[0]._prioritizeVertex = false;
  }
  /**
   * Gets the current class name
   * @returns the class name
   */
  getClassName() {
    return "CurrentScreenBlock";
  }
  /**
   * Gets the uv input component
   */
  get uv() {
    return this._inputs[0];
  }
  /**
   * Gets the rgba output component
   */
  get rgba() {
    return this._outputs[0];
  }
  /**
   * Gets the rgb output component
   */
  get rgb() {
    return this._outputs[1];
  }
  /**
   * Gets the r output component
   */
  get r() {
    return this._outputs[2];
  }
  /**
   * Gets the g output component
   */
  get g() {
    return this._outputs[3];
  }
  /**
   * Gets the b output component
   */
  get b() {
    return this._outputs[4];
  }
  /**
   * Gets the a output component
   */
  get a() {
    return this._outputs[5];
  }
  /**
   * Initialize the block and prepare the context for build
   * @param state defines the state that will be used for the build
   */
  initialize(state) {
    state._excludeVariableName(this._samplerName);
  }
  get target() {
    if (!this.uv.isConnected) {
      return NodeMaterialBlockTargets.VertexAndFragment;
    }
    if (this.uv.sourceBlock.isInput) {
      return NodeMaterialBlockTargets.VertexAndFragment;
    }
    return NodeMaterialBlockTargets.Fragment;
  }
  prepareDefines(mesh, nodeMaterial, defines) {
    defines.setValue(this._linearDefineName, this.convertToGammaSpace, true);
    defines.setValue(this._gammaDefineName, this.convertToLinearSpace, true);
  }
  isReady() {
    if (this.texture && !this.texture.isReadyOrNotBlocking()) {
      return false;
    }
    return true;
  }
  _injectVertexCode(state) {
    const uvInput = this.uv;
    if (uvInput.connectedPoint.ownerBlock.isInput) {
      const uvInputOwnerBlock = uvInput.connectedPoint.ownerBlock;
      if (!uvInputOwnerBlock.isAttribute) {
        state._emitUniformFromString(uvInput.associatedVariableName, NodeMaterialBlockConnectionPointTypes.Vector2);
      }
    }
    this._mainUVName = "vMain" + uvInput.associatedVariableName;
    state._emitVaryingFromString(this._mainUVName, NodeMaterialBlockConnectionPointTypes.Vector2);
    state.compilationString += `${this._mainUVName} = ${uvInput.associatedVariableName}.xy;\n`;
    if (!this._outputs.some(o => o.isConnectedInVertexShader)) {
      return;
    }
    this._writeTextureRead(state, true);
    for (const output of this._outputs) {
      if (output.hasEndpoints) {
        this._writeOutput(state, output, output.name, true);
      }
    }
  }
  _writeTextureRead(state, vertexMode = false) {
    const uvInput = this.uv;
    if (vertexMode) {
      if (state.target === NodeMaterialBlockTargets.Fragment) {
        return;
      }
      const textureReadFunc = state.shaderLanguage === 0 /* ShaderLanguage.GLSL */ ? `texture2D(${this._samplerName},` : `textureSampleLevel(${this._samplerName}, ${this._samplerName + `Sampler`},`;
      const complement = state.shaderLanguage === 0 /* ShaderLanguage.GLSL */ ? "" : ", 0";
      state.compilationString += `${state._declareLocalVar(this._tempTextureRead, NodeMaterialBlockConnectionPointTypes.Vector4)} = ${textureReadFunc} ${uvInput.associatedVariableName}${complement});\n`;
      return;
    }
    const textureReadFunc = state.shaderLanguage === 0 /* ShaderLanguage.GLSL */ ? `texture2D(${this._samplerName},` : `textureSample(${this._samplerName}, ${this._samplerName + `Sampler`},`;
    if (this.uv.ownerBlock.target === NodeMaterialBlockTargets.Fragment) {
      state.compilationString += `${state._declareLocalVar(this._tempTextureRead, NodeMaterialBlockConnectionPointTypes.Vector4)} = ${textureReadFunc} ${uvInput.associatedVariableName});\n`;
      return;
    }
    state.compilationString += `${state._declareLocalVar(this._tempTextureRead, NodeMaterialBlockConnectionPointTypes.Vector4)} = ${textureReadFunc} ${this._mainUVName});\n`;
  }
  _writeOutput(state, output, swizzle, vertexMode = false) {
    if (vertexMode) {
      if (state.target === NodeMaterialBlockTargets.Fragment) {
        return;
      }
      state.compilationString += `${state._declareOutput(output)} = ${this._tempTextureRead}.${swizzle};\n`;
      return;
    }
    if (this.uv.ownerBlock.target === NodeMaterialBlockTargets.Fragment) {
      state.compilationString += `${state._declareOutput(output)} = ${this._tempTextureRead}.${swizzle};\n`;
      return;
    }
    state.compilationString += `${state._declareOutput(output)} = ${this._tempTextureRead}.${swizzle};\n`;
    state.compilationString += `#ifdef ${this._linearDefineName}\n`;
    state.compilationString += `${output.associatedVariableName} = toGammaSpace(${output.associatedVariableName});\n`;
    state.compilationString += `#endif\n`;
    state.compilationString += `#ifdef ${this._gammaDefineName}\n`;
    state.compilationString += `${output.associatedVariableName} = toLinearSpace(${output.associatedVariableName});\n`;
    state.compilationString += `#endif\n`;
  }
  _buildBlock(state) {
    super._buildBlock(state);
    this._tempTextureRead = state._getFreeVariableName("tempTextureRead");
    if (state.sharedData.blockingBlocks.indexOf(this) < 0) {
      state.sharedData.blockingBlocks.push(this);
    }
    if (state.sharedData.textureBlocks.indexOf(this) < 0) {
      state.sharedData.textureBlocks.push(this);
    }
    if (state.sharedData.blocksWithDefines.indexOf(this) < 0) {
      state.sharedData.blocksWithDefines.push(this);
    }
    if (state.target !== NodeMaterialBlockTargets.Fragment) {
      // Vertex
      state._emit2DSampler(this._samplerName);
      this._injectVertexCode(state);
      return;
    }
    // Fragment
    if (!this._outputs.some(o => o.isConnectedInFragmentShader)) {
      return;
    }
    state._emit2DSampler(this._samplerName);
    this._linearDefineName = state._getFreeDefineName("ISLINEAR");
    this._gammaDefineName = state._getFreeDefineName("ISGAMMA");
    const comments = `//${this.name}`;
    state._emitFunctionFromInclude("helperFunctions", comments);
    this._writeTextureRead(state);
    for (const output of this._outputs) {
      if (output.hasEndpoints) {
        this._writeOutput(state, output, output.name);
      }
    }
    return this;
  }
  serialize() {
    const serializationObject = super.serialize();
    serializationObject.convertToGammaSpace = this.convertToGammaSpace;
    serializationObject.convertToLinearSpace = this.convertToLinearSpace;
    if (this.texture && !this.texture.isRenderTarget) {
      serializationObject.texture = this.texture.serialize();
    }
    return serializationObject;
  }
  _deserialize(serializationObject, scene, rootUrl) {
    super._deserialize(serializationObject, scene, rootUrl);
    this.convertToGammaSpace = serializationObject.convertToGammaSpace;
    this.convertToLinearSpace = !!serializationObject.convertToLinearSpace;
    if (serializationObject.texture) {
      rootUrl = serializationObject.texture.url.indexOf("data:") === 0 ? "" : rootUrl;
      this.texture = Texture.Parse(serializationObject.texture, scene, rootUrl);
    }
  }
}
RegisterClass("BABYLON.CurrentScreenBlock", CurrentScreenBlock);
