import { __decorate } from "../../../../tslib.es6.js";
import { NodeRenderGraphBlock } from "../../nodeRenderGraphBlock.js";
import { RegisterClass } from "../../../../Misc/typeStore.js";
import { NodeRenderGraphBlockConnectionPointTypes } from "../../Types/nodeRenderGraphTypes.js";
import { editableInPropertyPage } from "../../../../Decorators/nodeDecorator.js";
import { FrameGraphExtractHighlightsTask } from "../../../Tasks/PostProcesses/extractHighlightsTask.js";
import { ThinExtractHighlightsPostProcess } from "../../../../PostProcesses/thinExtractHighlightsPostProcess.js";
/**
 * Block that implements the extract highlights post process
 */
export class NodeRenderGraphExtractHighlightsPostProcessBlock extends NodeRenderGraphBlock {
  /**
   * Gets the frame graph task associated with this block
   */
  get task() {
    return this._frameGraphTask;
  }
  /**
   * Create a new ExtractHighlightsPostProcessBlock
   * @param name defines the block name
   * @param frameGraph defines the hosting frame graph
   * @param scene defines the hosting scene
   */
  constructor(name, frameGraph, scene) {
    super(name, frameGraph, scene);
    this.registerInput("source", NodeRenderGraphBlockConnectionPointTypes.Texture);
    this.registerInput("destination", NodeRenderGraphBlockConnectionPointTypes.Texture, true);
    this.registerOutput("output", NodeRenderGraphBlockConnectionPointTypes.BasedOnInput);
    this.source.addAcceptedConnectionPointTypes(NodeRenderGraphBlockConnectionPointTypes.TextureAllButBackBuffer);
    this.destination.addAcceptedConnectionPointTypes(NodeRenderGraphBlockConnectionPointTypes.TextureAll);
    this.output._typeConnectionSource = () => {
      return this.destination.isConnected ? this.destination : this.source;
    };
    this._frameGraphTask = new FrameGraphExtractHighlightsTask(this.name, frameGraph, new ThinExtractHighlightsPostProcess(name, scene.getEngine()));
  }
  /** Sampling mode used to sample from the source texture */
  get sourceSamplingMode() {
    return this._frameGraphTask.sourceSamplingMode;
  }
  set sourceSamplingMode(value) {
    this._frameGraphTask.sourceSamplingMode = value;
  }
  /** The luminance threshold, pixels below this value will be set to black. */
  get threshold() {
    return this._frameGraphTask.postProcess.threshold;
  }
  set threshold(value) {
    this._frameGraphTask.postProcess.threshold = value;
  }
  /**
   * Gets the current class name
   * @returns the class name
   */
  getClassName() {
    return "NodeRenderGraphExtractHighlightsPostProcessBlock";
  }
  /**
   * Gets the source input component
   */
  get source() {
    return this._inputs[0];
  }
  /**
   * Gets the destination input component
   */
  get destination() {
    return this._inputs[1];
  }
  /**
   * Gets the output component
   */
  get output() {
    return this._outputs[0];
  }
  _buildBlock(state) {
    super._buildBlock(state);
    this._frameGraphTask.name = this.name;
    this.output.value = this._frameGraphTask.outputTexture; // the value of the output connection point is the "output" texture of the task
    const sourceConnectedPoint = this.source.connectedPoint;
    if (sourceConnectedPoint) {
      this._frameGraphTask.sourceTexture = sourceConnectedPoint.value;
    }
    const destinationConnectedPoint = this.destination.connectedPoint;
    if (destinationConnectedPoint) {
      this._frameGraphTask.destinationTexture = destinationConnectedPoint.value;
    }
  }
  _dumpPropertiesCode() {
    const codes = [];
    codes.push(`${this._codeVariableName}.threshold = ${this.threshold};`);
    codes.push(`${this._codeVariableName}.sourceSamplingMode = ${this.sourceSamplingMode};`);
    return super._dumpPropertiesCode() + codes.join("\n");
  }
  serialize() {
    const serializationObject = super.serialize();
    serializationObject.threshold = this.threshold;
    serializationObject.sourceSamplingMode = this.sourceSamplingMode;
    return serializationObject;
  }
  _deserialize(serializationObject) {
    super._deserialize(serializationObject);
    this.threshold = serializationObject.threshold;
    this.sourceSamplingMode = serializationObject.sourceSamplingMode;
  }
}
__decorate([editableInPropertyPage("Source sampling mode", 6 /* PropertyTypeForEdition.SamplingMode */, "PROPERTIES")], NodeRenderGraphExtractHighlightsPostProcessBlock.prototype, "sourceSamplingMode", null);
__decorate([editableInPropertyPage("Threshold", 1 /* PropertyTypeForEdition.Float */, "PROPERTIES", {
  min: 0,
  max: 1
})], NodeRenderGraphExtractHighlightsPostProcessBlock.prototype, "threshold", null);
RegisterClass("BABYLON.NodeRenderGraphExtractHighlightsPostProcessBlock", NodeRenderGraphExtractHighlightsPostProcessBlock);
