import { Component } from '@angular/core';

@Component({
	templateUrl: './key-features.component.html',
	standalone: true,
	selector: 'app-key-features',
	styleUrl: './key-features.component.scss',
	imports: [],
})
export class KeyFeaturesComponent {}
