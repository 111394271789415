<div class="header">
	<div class="flexible-container">
		<div class="row">

				<a class="logo" tuiLink routerLink="/">
					it's Deprecated!
				</a>

				<div class="menu" *appDevice="'desktop'">

					<div class="counter">refactoring stage: {{ counter() | plural: 'days': 'day' }}</div>
					<app-theme-changer></app-theme-changer>

					@if(authService.payload | async) {
						<app-account-link></app-account-link>
					} @else {
						<button
							class="login"
							tuiButton
							appearance="transparent"
							(click)="openLogin()" iconEnd="@tui.log-in"></button>
					}
					<a appCvButton routerLink="/cv"></a>

					@if (isOffline()) {
						<app-loader></app-loader>
					}
				</div>
			<div class="mobile-buttons" *appDevice="['tablet', 'mobile']">

				<app-drawer-menu
					*appDevice="['mobile', 'tablet']"
					[content]="drawer"
					menuTitle="Reactive Deprecated"
				></app-drawer-menu>

				<app-install-button *appDevice="['mobile', 'tablet']"></app-install-button>


				<app-theme-changer class="theme-changer"></app-theme-changer>
				<a appCvButton routerLink="/cv"></a>
				@if (isOffline()) {
					<app-loader></app-loader>
				}
			</div>
		</div>
	</div>
</div>

<ng-template #drawer>
	<div class="drawer-content">
		<div class="links">
			@for (item of headerService.navigation(); track $index) {
				<a
					class="drawer-link"
					tuiLink
					(click)="item.children ? openExpand() : 0"
				>
					<span class="link-text">
						{{ item.title }}
					</span>
				</a>
				@if(item.children) {
					<tui-expand
						class="expanded-content"
						[expanded]="expanded()"
					>
						<ul>
							@for (subItem of item.children; track $index) {
								<li>
									<a
										class="sub-link"
										tuiLink
										[routerLink]="subItem.routerLink"
									>
										<span class="sublink-text">

											{{ subItem.title }}
										</span>
									</a>
								</li>
							}
						</ul>
					</tui-expand>
				}

			}
		</div>

		<div class="counter">refactoring stage: {{ counter() | plural: 'days': 'day' }}</div>

	</div>
</ng-template>
