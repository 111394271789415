import type { OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, signal } from '@angular/core';
import { TuiIcon } from '@taiga-ui/core';

@Component({
	standalone: true,
	templateUrl: './online-info.component.html',
	styleUrl: './online-info.component.scss',
	selector: 'app-online',
	imports: [TuiIcon],
	animations: [
		trigger('hide', [
			transition(':leave', [
				style({ transform: 'translateY(0)', opacity: 1 }),
				animate('300ms ease-out', style({ transform: 'translateY(-100%)', opacity: 0 })),
			]),
		]),
	],
})
export class OnlineInfoComponent implements OnInit {
	protected onHide = signal(false);

	@HostListener('window:beforeunload', ['$event'])
	onBeforeUnload(): void {
		this.onHide.set(false);
	}

	ngOnInit(): void {
		setTimeout(() => this.onHide.update((v) => !v), 2000);
	}
}
