import type { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { inject, Injectable, NgZone } from '@angular/core';
import { fromEvent, map, startWith } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ScrollService {
	private readonly window = inject(DOCUMENT).defaultView;
	private readonly ngZone = inject(NgZone);

	readonly position = this.calcPosition(this.window!);

	private calcPosition(el: Element | Window): Observable<{ x: number; y: number }> {
		return this.ngZone.run(() =>
			fromEvent(el, 'scroll').pipe(
				startWith(null),
				map(() => {
					if (el instanceof Window) {
						return {
							x: el.scrollX,
							y: el.scrollY,
						};
					} else {
						return {
							x: el.scrollLeft,
							y: el.scrollTop,
						};
					}
				}),
			),
		);
	}
}
