import { Component } from '@angular/core';

@Component({
	selector: 'app-loader',
	template: `
		<svg class="spinner" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
			<circle class="path" fill="none" stroke-width="4" stroke-linecap="round" cx="20" cy="20" r="16"></circle>
		</svg>
	`,
	styleUrls: ['./loader.component.scss'],
	standalone: true,
})
export class LoaderComponent {}
