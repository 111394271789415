import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TuiLink } from '@taiga-ui/core';

@Component({
	selector: 'app-account-link',
	standalone: true,
	imports: [CommonModule, TuiLink, RouterLink],
	templateUrl: './user-account-link.component.html',
	styleUrl: './user-account-link.component.scss',
	encapsulation: ViewEncapsulation.Emulated,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAccountLinkComponent {}
