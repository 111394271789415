import { AsyncPipe } from '@angular/common';
import { Component, computed, DestroyRef, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { AuthDialogComponent } from '@core/components';
import { CvButtonComponent } from '@core/components/header/cv-button.component';
import { AuthService } from '@core/services';
import { TuiButton, TuiDialogService, TuiExpand, TuiLink } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@taiga-ui/polymorpheus';
import { DrawerMenuComponent, LoaderComponent, ThemeChangerComponent, UserAccountLinkComponent } from '@ui/components';
import { DeviceDirective } from '@ui/directives';
import { PluralPipe } from '@ui/pipes';
import { OnlineService } from '@ui/services';
import * as luxon from 'luxon';
import { map, timer } from 'rxjs';
import { HeaderService } from './header.service';
import { InstallButtonComponent } from './install/install-button.component';

@Component({
	standalone: true,
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrl: './header.component.scss',
	imports: [
		PluralPipe,
		TuiExpand,
		DrawerMenuComponent,
		RouterLink,
		ThemeChangerComponent,
		DeviceDirective,
		InstallButtonComponent,
		TuiLink,
		CvButtonComponent,
		TuiButton,
		AsyncPipe,
		UserAccountLinkComponent,
		LoaderComponent,
	],
	providers: [HeaderService],
})
export class HeaderComponent {
	private readonly dialogService = inject(TuiDialogService);
	private readonly destroyRef = inject(DestroyRef);
	private readonly onlineService = inject(OnlineService);

	readonly authService = inject(AuthService);
	readonly headerService = inject(HeaderService);
	readonly expanded = signal(false);
	readonly isOffline = computed(() => {
		return !this.onlineService.isOnline();
	});

	readonly counter = toSignal(
		timer(1000).pipe(
			map(() => {
				const startDate = luxon.DateTime.fromJSDate(new Date('2024-06-25'));
				const now = luxon.DateTime.fromJSDate(new Date());

				return Math.floor(now.diff(startDate, 'days').days);
			}),
		),
		{
			initialValue: 0,
		},
	);

	openExpand(): void {
		this.expanded.update((value) => !value);
	}

	openLogin(): void {
		this.dialogService
			.open(new PolymorpheusComponent(AuthDialogComponent))
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe();
	}
}
