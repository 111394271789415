import { Vector3 } from "../../Maths/math.vector.js";
import { RandomRange } from "../../Maths/math.scalar.functions.js";
import { DeepCopier } from "../../Misc/deepCopier.js";
/**
 * Particle emitter emitting particles from the inside of a cylinder.
 * It emits the particles alongside the cylinder radius. The emission direction might be randomized.
 */
export class CylinderParticleEmitter {
  /**
   * Creates a new instance CylinderParticleEmitter
   * @param radius the radius of the emission cylinder (1 by default)
   * @param height the height of the emission cylinder (1 by default)
   * @param radiusRange the range of the emission cylinder [0-1] 0 Surface only, 1 Entire Radius (1 by default)
   * @param directionRandomizer defines how much to randomize the particle direction [0-1]
   */
  constructor(
  /**
   * [1] The radius of the emission cylinder.
   */
  radius = 1,
  /**
   * [1] The height of the emission cylinder.
   */
  height = 1,
  /**
   * [1] The range of emission [0-1] 0 Surface only, 1 Entire Radius.
   */
  radiusRange = 1,
  /**
   * [0] How much to randomize the particle direction [0-1].
   */
  directionRandomizer = 0) {
    this.radius = radius;
    this.height = height;
    this.radiusRange = radiusRange;
    this.directionRandomizer = directionRandomizer;
    this._tempVector = Vector3.Zero();
  }
  /**
   * Called by the particle System when the direction is computed for the created particle.
   * @param worldMatrix is the world matrix of the particle system
   * @param directionToUpdate is the direction vector to update with the result
   * @param particle is the particle we are computed the direction for
   * @param isLocal defines if the direction should be set in local space
   * @param inverseWorldMatrix defines the inverted world matrix to use if isLocal is false
   */
  startDirectionFunction(worldMatrix, directionToUpdate, particle, isLocal, inverseWorldMatrix) {
    particle.position.subtractToRef(worldMatrix.getTranslation(), this._tempVector);
    this._tempVector.normalize();
    Vector3.TransformNormalToRef(this._tempVector, inverseWorldMatrix, this._tempVector);
    const randY = RandomRange(-this.directionRandomizer / 2, this.directionRandomizer / 2);
    let angle = Math.atan2(this._tempVector.x, this._tempVector.z);
    angle += RandomRange(-Math.PI / 2, Math.PI / 2) * this.directionRandomizer;
    this._tempVector.y = randY; // set direction y to rand y to mirror normal of cylinder surface
    this._tempVector.x = Math.sin(angle);
    this._tempVector.z = Math.cos(angle);
    this._tempVector.normalize();
    if (isLocal) {
      directionToUpdate.copyFrom(this._tempVector);
      return;
    }
    Vector3.TransformNormalFromFloatsToRef(this._tempVector.x, this._tempVector.y, this._tempVector.z, worldMatrix, directionToUpdate);
  }
  /**
   * Called by the particle System when the position is computed for the created particle.
   * @param worldMatrix is the world matrix of the particle system
   * @param positionToUpdate is the position vector to update with the result
   * @param particle is the particle we are computed the position for
   * @param isLocal defines if the position should be set in local space
   */
  startPositionFunction(worldMatrix, positionToUpdate, particle, isLocal) {
    const yPos = RandomRange(-this.height / 2, this.height / 2);
    const angle = RandomRange(0, 2 * Math.PI);
    // Pick a properly distributed point within the circle https://programming.guide/random-point-within-circle.html
    const radiusDistribution = RandomRange((1 - this.radiusRange) * (1 - this.radiusRange), 1);
    const positionRadius = Math.sqrt(radiusDistribution) * this.radius;
    const xPos = positionRadius * Math.cos(angle);
    const zPos = positionRadius * Math.sin(angle);
    if (isLocal) {
      positionToUpdate.copyFromFloats(xPos, yPos, zPos);
      return;
    }
    Vector3.TransformCoordinatesFromFloatsToRef(xPos, yPos, zPos, worldMatrix, positionToUpdate);
  }
  /**
   * Clones the current emitter and returns a copy of it
   * @returns the new emitter
   */
  clone() {
    const newOne = new CylinderParticleEmitter(this.radius, this.directionRandomizer);
    DeepCopier.DeepCopy(this, newOne);
    return newOne;
  }
  /**
   * Called by the GPUParticleSystem to setup the update shader
   * @param uboOrEffect defines the update shader
   */
  applyToShader(uboOrEffect) {
    uboOrEffect.setFloat("radius", this.radius);
    uboOrEffect.setFloat("height", this.height);
    uboOrEffect.setFloat("radiusRange", this.radiusRange);
    uboOrEffect.setFloat("directionRandomizer", this.directionRandomizer);
  }
  /**
   * Creates the structure of the ubo for this particle emitter
   * @param ubo ubo to create the structure for
   */
  buildUniformLayout(ubo) {
    ubo.addUniform("radius", 1);
    ubo.addUniform("height", 1);
    ubo.addUniform("radiusRange", 1);
    ubo.addUniform("directionRandomizer", 1);
  }
  /**
   * Returns a string to use to update the GPU particles update shader
   * @returns a string containing the defines string
   */
  getEffectDefines() {
    return "#define CYLINDEREMITTER";
  }
  /**
   * Returns the string "CylinderParticleEmitter"
   * @returns a string containing the class name
   */
  getClassName() {
    return "CylinderParticleEmitter";
  }
  /**
   * Serializes the particle system to a JSON object.
   * @returns the JSON object
   */
  serialize() {
    const serializationObject = {};
    serializationObject.type = this.getClassName();
    serializationObject.radius = this.radius;
    serializationObject.height = this.height;
    serializationObject.radiusRange = this.radiusRange;
    serializationObject.directionRandomizer = this.directionRandomizer;
    return serializationObject;
  }
  /**
   * Parse properties from a JSON object
   * @param serializationObject defines the JSON object
   */
  parse(serializationObject) {
    this.radius = serializationObject.radius;
    this.height = serializationObject.height;
    this.radiusRange = serializationObject.radiusRange;
    this.directionRandomizer = serializationObject.directionRandomizer;
  }
}
/**
 * Particle emitter emitting particles from the inside of a cylinder.
 * It emits the particles randomly between two vectors.
 */
export class CylinderDirectedParticleEmitter extends CylinderParticleEmitter {
  /**
   * Creates a new instance CylinderDirectedParticleEmitter
   * @param radius the radius of the emission cylinder (1 by default)
   * @param height the height of the emission cylinder (1 by default)
   * @param radiusRange the range of the emission cylinder [0-1] 0 Surface only, 1 Entire Radius (1 by default)
   * @param direction1 the min limit of the emission direction (up vector by default)
   * @param direction2 the max limit of the emission direction (up vector by default)
   */
  constructor(radius = 1, height = 1, radiusRange = 1,
  /**
   * [Up vector] The min limit of the emission direction.
   */
  direction1 = new Vector3(0, 1, 0),
  /**
   * [Up vector] The max limit of the emission direction.
   */
  direction2 = new Vector3(0, 1, 0)) {
    super(radius, height, radiusRange);
    this.direction1 = direction1;
    this.direction2 = direction2;
  }
  /**
   * Called by the particle System when the direction is computed for the created particle.
   * @param worldMatrix is the world matrix of the particle system
   * @param directionToUpdate is the direction vector to update with the result
   * @param _particle is the particle we are computed the direction for
   * @param isLocal defines if the direction should be set in local space
   */
  startDirectionFunction(worldMatrix, directionToUpdate, _particle, isLocal) {
    const randX = RandomRange(this.direction1.x, this.direction2.x);
    const randY = RandomRange(this.direction1.y, this.direction2.y);
    const randZ = RandomRange(this.direction1.z, this.direction2.z);
    if (isLocal) {
      directionToUpdate.copyFromFloats(randX, randY, randZ);
      return;
    }
    Vector3.TransformNormalFromFloatsToRef(randX, randY, randZ, worldMatrix, directionToUpdate);
  }
  /**
   * Clones the current emitter and returns a copy of it
   * @returns the new emitter
   */
  clone() {
    const newOne = new CylinderDirectedParticleEmitter(this.radius, this.height, this.radiusRange, this.direction1, this.direction2);
    DeepCopier.DeepCopy(this, newOne);
    return newOne;
  }
  /**
   * Called by the GPUParticleSystem to setup the update shader
   * @param uboOrEffect defines the update shader
   */
  applyToShader(uboOrEffect) {
    uboOrEffect.setFloat("radius", this.radius);
    uboOrEffect.setFloat("height", this.height);
    uboOrEffect.setFloat("radiusRange", this.radiusRange);
    uboOrEffect.setVector3("direction1", this.direction1);
    uboOrEffect.setVector3("direction2", this.direction2);
  }
  /**
   * Creates the structure of the ubo for this particle emitter
   * @param ubo ubo to create the structure for
   */
  buildUniformLayout(ubo) {
    ubo.addUniform("radius", 1);
    ubo.addUniform("height", 1);
    ubo.addUniform("radiusRange", 1);
    ubo.addUniform("direction1", 3);
    ubo.addUniform("direction2", 3);
  }
  /**
   * Returns a string to use to update the GPU particles update shader
   * @returns a string containing the defines string
   */
  getEffectDefines() {
    return "#define CYLINDEREMITTER\n#define DIRECTEDCYLINDEREMITTER";
  }
  /**
   * Returns the string "CylinderDirectedParticleEmitter"
   * @returns a string containing the class name
   */
  getClassName() {
    return "CylinderDirectedParticleEmitter";
  }
  /**
   * Serializes the particle system to a JSON object.
   * @returns the JSON object
   */
  serialize() {
    const serializationObject = super.serialize();
    serializationObject.direction1 = this.direction1.asArray();
    serializationObject.direction2 = this.direction2.asArray();
    return serializationObject;
  }
  /**
   * Parse properties from a JSON object
   * @param serializationObject defines the JSON object
   */
  parse(serializationObject) {
    super.parse(serializationObject);
    Vector3.FromArrayToRef(serializationObject.direction1, 0, this.direction1);
    Vector3.FromArrayToRef(serializationObject.direction2, 0, this.direction2);
  }
}
