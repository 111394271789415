<div class="drawer" tabindex="0" #drawer>
	<div class="header" style="display: flex">
		<div class="title jumbotron">
			{{ context.title }}
		</div>


		<tui-icon class="close" (click)="closeDrawer()" icon="@tui.x"></tui-icon>
		<app-account-link class="account"></app-account-link>
	</div>

	<div class="content">
		<ng-container *polymorpheusOutlet="context.content as text">
			{{ text }}
		</ng-container>
	</div>
</div>
